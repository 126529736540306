export const Breakpoints = {
  MOBILE: 576,
  TABLET: 768,
  LAPTOP: 992,
  DESKTOP: 1200,
};

export const Theme = {
  "primary": "#000000",
  "secondary": "#C36C58",
  "black": "#000",
  "white": "#fff",

  "gray_light": "#fafafa",
  "gray_semilight": "#dddddd",
  "gray_regular": "#a2a2a2",
  "grey_regular_bold": "#999",
  "grey_regular_semibold": "#808080",
  "grey_light_primary": "#c7c7c7",
  "gray_semibold": "#757575",
  "gray_bold": "#4a4a4a",
  "grey_silver": "#7a7a7a",
  "grey_separator": "#d8d8d8",
  "grey_light_secondary": "#f7f6f6",

  "blue_regular": "#127392",
  "blue_primary": "#007bff",
  "green_regular": "#07815c",
  "green_light": "#04aa6d",
  "red_regular": "#f15a59",
  "yellow_regular": "#fece40",

  "error_bg": "#fdebeb"
}

export const cultures = {
  'en-us': 'USA',
  'en-ca': 'Canada',
  'en-gb': 'Europe',
  'en-ap': 'Asia',
  'en-au': 'Australia',
  'en-cn': 'China',
  'en-in': 'India',
  'es-la': 'Spanish',
  'es-mx': 'Mexico',
  'pt-br': 'Portuguese',
  'en-nz': 'New Zealand',
  'ko-kr': '한국어',
  'ja-jp': '日本語',
  'zh-cn': '简体字"? "简化字',
};

export const DesignAwards = {
  JUDGES_VOTE: 'Judge Voting',
  SUBMISSION: 'Submission',
  PEOPLE_CHOICE: 'PeoplesVoting',
  FINAL_WINNERS: 'FinalWinners',
};

export const requestStatusTypes = {
  idle: 0,
  pending: 3,
  complete: 4,
};

export const imageFilesTypes = [
  { type: 'image/png' },
  { type: 'image/jpeg' },
];
