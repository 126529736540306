import { _boardDenorm } from './helpers/boardDenorm';

export const getAllBaseDenorm = (response = {}) => {
  if (Array.isArray(response?.boards)) {
    return {
      ...response,
      boards: response.boards.map(_boardDenorm),
    };
  }
  return response;
};
