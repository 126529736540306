import SocialMediaConstants from './Constants';

const { Types, SharingOptions } = SocialMediaConstants;

function getSocialMediaSharingLink(platform, options = {}) {
  const { baseURL } = SharingOptions[platform];
  const {
    linkToShare = location.href,
    title = '',
    description = '',
    media = '',
    body = '',
    user = '',
    subject = '',
  } = options;
  let shareUrl = '';

  switch (platform) {
    case Types.FACEBOOK:
      shareUrl = `${baseURL + linkToShare}`;
      break;
    case Types.TWITTER:
      shareUrl = `${baseURL + linkToShare}&title=${title}`;
      break;
    case Types.PINTEREST:
      shareUrl = encodeURI(`${baseURL + linkToShare}&media=${media}&description=${description}`);
      break;
    case Types.REDDIT:
      shareUrl = `${baseURL + linkToShare}&title=${title}`;
      break;
    case Types.LINKEDIN:
      shareUrl = `${baseURL + linkToShare}`;
      break;
    case Types.MAIL:
      shareUrl = `${baseURL + user}&body=${body}&subject=${subject}`;
      break;

    default:
      // eslint-disable-next-line no-console
      console.error('Invalid social media platform type.');
      break;
  }

  return shareUrl;
}

export default {
  Types,
  getSocialMediaSharingLink,
};
