/* eslint-disable no-restricted-globals */
const RequestSignInEndpoint = $('#RequestSignInEndpoint').val();

function changeLoginRedirect() {
  const loginElements = document.getElementsByClassName('login-link');

  for (const a of loginElements) {
    a.href = `${location.origin}${RequestSignInEndpoint}?returnUrl=${location.pathname}${encodeURIComponent(location.search)}`;
 
    a.addEventListener('click', ()=> {
      dataLayer.push({
        'event': 'login-attempt',
      });
    });
  }
}

changeLoginRedirect();

export function redirectToSignIn() {
  location.href = `${location.origin}${RequestSignInEndpoint}?returnUrl=${location.pathname}${encodeURIComponent(location.search)}`;
}

export default { changeLoginRedirect };
