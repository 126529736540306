function StateObject(obj) {
  if (typeof obj !== 'object') {
    this.state = {};
  }
  this.state = obj;

  return this;
}

StateObject.prototype.get = function get(property) {
  return this.state[property] || this.state;
};

/**
 *
 * @param {Function | {}} arg1
 */
StateObject.prototype.set = function set(arg1) {
  if (typeof arg1 === 'function') {
    const newState = arg1(this.state);
    this.state = { ...this.state, ...newState };
    return this.state;
  }

  this.state = { ...this.state, ...arg1 };
  return this.state;
};

export default StateObject;
