import { boardTypes, boardStatuses, brandBoardStatuses } from './boardTypes';
import { ContentItemTypes, productTypes } from './types';
import { boardRoles } from './boardRoles';

export const isProduct = (type) => ContentItemTypes.product === type;
export const isCollection = (type) => ContentItemTypes.collection === type;
export const isArticle = (type) => ContentItemTypes.article === type;
export const isPDF = (type) => ContentItemTypes.pdf === type;
export const isVideo = (type) => ContentItemTypes.video === type;
export const isOther = (type) => ContentItemTypes.other === type;
export const isOwnVideo = (type) => ContentItemTypes.ownVideo === type;
export const isPaint = (type) => ContentItemTypes.paint === type;
export const isPhotography = (type) => ContentItemTypes.photography === type;
export const isWebpage = (type) => ContentItemTypes.page === type;
export const isResource = (type) => isArticle(type) || isPDF(type) || isOther(type) || isVideo(type) || isPhotography(type);

export const isRug = (type) => type?.includes(productTypes.rug);

export const isArchived = (statusType) => boardStatuses.archived === statusType;
export const isActive = (statusType) => boardStatuses.active === statusType;
export const isBrandBoard = (boardType) => boardTypes.brand === boardType;
export const isPersonal = (boardType) => boardTypes.personal === boardType;

export const isPublished = (publishStatus) => brandBoardStatuses.published === publishStatus;
export const isDraft = (publishStatus) => brandBoardStatuses.draft === publishStatus;
export const isUnpublished = (publishStatus) => brandBoardStatuses.unpublished === publishStatus;

export const isOwner = (role) => role === boardRoles.owner;
export const isCollaborator = (role) => role === boardRoles.collaborator;
export const isCommenter = (role) => role === boardRoles.commenter;
export const isViewer = (role) => role === boardRoles.viewer;
