const reloadCartEndpoint = $('#reloadCart').val();
const cartDropdown = $('#cart');
const cartBadge = $('#cart-badge');

export function reloadCartDropdown() {
  $.ajax({
    url: reloadCartEndpoint,
    cache: false,
    dataType: 'html',
    async: true,
    success(data) {
      cartDropdown.html(data);
      cartBadge.text($('#total-items-count-hidden').val());
      $('#cart-items-count-number').text($('#total-items-count-hidden').val());
    },
  });
}
