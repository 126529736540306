/* eslint-disable newline-per-chained-call */
import { utility } from 'modules/utility';
import { Endpoint } from './EndpointClass';
import { getAllDenorm, getAllBaseDenorm, getBoardDenorm } from './Denormalizers/Boards';

const { i18n } = ShawContract;
const boardSuccessUpdated = i18n['Boards.Toastr.SuccessUpdated'];
const boardSuccessCreated = i18n['Boards.Toastr.SuccessCreated'];
const boardSuccessShared = i18n['SharedBoard.Share.SuccessMsgTitle'];
const jsonValue = document.getElementById('board-privacy')?.value;
const isPrivate = jsonValue && JSON.parse(jsonValue);

const baseWebApi = $('#web-api-base-url').val();

const baseMVCApi = `/${utility.getPageCulture()}`;
const baseProductMVCApi = `${baseMVCApi}/product/`;

const baseWebProjApi = `${baseMVCApi}/BoardsProjService/`;

const baseBoardUrl = `${baseWebApi}/Boards/`;
const baseBoardUserUrl = `${baseWebApi}/BoardUsers/`;
const baseSectionUrl = `${baseWebApi}/Sections/`;
const baseItemUrl = `${baseMVCApi}/BoardsContentItems/`;

const BoardsAPI = {
  GetAll: new Endpoint('get', 'GetAll', baseWebProjApi)
    .auth().denormalize(getAllDenorm).getAjax(),

  GetAllBase: new Endpoint('get', 'GetAllBase', baseBoardUrl)
    .auth().denormalize(getAllBaseDenorm).getAjax(),

  Get: new Endpoint('get', 'Get', baseBoardUrl)
    .auth(isPrivate).denormalize(getBoardDenorm).getAjax(),

  Create: new Endpoint('post', 'Create', baseWebProjApi)
    .json().auth().getAjax(),

  Update: new Endpoint('put', 'Update', baseBoardUrl)
    .json().msg(boardSuccessUpdated).auth().getAjax(),

  Delete: new Endpoint('delete', 'Delete', baseBoardUrl)
    .json().auth().getAjax(),

  ChangeStatus: new Endpoint('put', 'ChangeStatus', baseBoardUrl)
    .json().msg(boardSuccessUpdated).auth().getAjax(),

  Duplicate: new Endpoint('post', 'Duplicate', baseBoardUrl)
    .json().auth().getAjax(),

  Publish: new Endpoint('post', 'Publish', baseBoardUrl)
    .json().auth().getAjax(),

  Unpublish: new Endpoint('post', 'Unpublish', baseBoardUrl)
    .json().auth().getAjax(),

  UpdateAzureIndexes: new Endpoint('post', '/ProductBoards/UpdateBrandBoardDocument', baseMVCApi)
    .getAjax(),

  GetBoardUsers: new Endpoint('get', 'GetUsers', baseBoardUserUrl)
    .auth().getAjax(),

  AddBoardUsers: new Endpoint('post', 'Create', baseBoardUserUrl)
    .json().msg(boardSuccessShared).auth().getAjax(),

  UpdateBoardUsers: new Endpoint('post', 'Save', baseBoardUserUrl)
    .json().msg(boardSuccessUpdated).auth().getAjax(),

  SendEmailToNewUsers: new Endpoint('post', '/productboards/shareboard', baseMVCApi)
    .json().getAjax(),

  ForceGetNewToken: new Endpoint('get', '', '')
    .auth().getAjax(),

  Sections: {
    Create: new Endpoint('post', 'Create', baseSectionUrl)
      .json().msg(boardSuccessUpdated).auth().getAjax(),

    Delete: new Endpoint('delete', 'Delete', baseSectionUrl)
      .json().msg(boardSuccessUpdated).auth().getAjax(),

    Update: new Endpoint('put', 'Update', baseSectionUrl)
      .json().msg(boardSuccessUpdated).auth().getAjax(),

    UpdateOrder: new Endpoint('put', 'UpdateOrder', baseSectionUrl)
      .json().msg(boardSuccessUpdated).auth().getAjax(),
  },

  ContentItems: {
    Create: new Endpoint('post', 'Create', baseItemUrl)
      .json().msg(boardSuccessUpdated).auth().getAjax(),

    Delete: new Endpoint('delete', 'Delete', baseItemUrl)
      .json().msg(boardSuccessUpdated).auth().getAjax(),

    DeleteMany: new Endpoint('delete', 'DeleteMany', baseItemUrl)
      .json().msg(boardSuccessUpdated).auth().getAjax(),

    Update: new Endpoint('put', 'Update', baseItemUrl)
      .json().msg(boardSuccessUpdated).auth().getAjax(),

    UpdateColors: new Endpoint('put', 'UpdateColors', baseItemUrl)
      .json().msg(boardSuccessUpdated).auth().getAjax(),

    UpdateFeatureImage: new Endpoint('put', 'UpdateFeatureImage', baseItemUrl)
      .json().msg(boardSuccessUpdated).auth().getAjax(),

    ChangeOrder: new Endpoint('put', 'ChangeOrder', baseItemUrl)
      .json().msg(boardSuccessUpdated).auth().getAjax(),

    CreateMany: new Endpoint('post', 'CreateMany', baseItemUrl)
      .json().msg(boardSuccessUpdated).auth().getAjax(),
  },

  Products: {
    GetProductsTypes: new Endpoint('get', 'BoardsProductTypes', baseProductMVCApi)
      .getAjax(),

    GetBrandTypes: new Endpoint('get', 'BrandsWeLoveProductTypes', baseProductMVCApi)
      .getAjax(),

    GetProductsByType: new Endpoint('get', 'producttiles', baseProductMVCApi)
      .getAjax(),

    GetCreateData: new Endpoint('get', 'GetCreateData', baseProductMVCApi)
      .camelCase().getAjax(),

    BoardProductDetails: new Endpoint('get', 'BoardProductDetails', baseProductMVCApi)
      .getAjax(),

    GetBoardProductsImg: new Endpoint('get', 'GetProductsImageGalery', baseProductMVCApi)
      .getAjax(),
  },

};

export { BoardsAPI };
