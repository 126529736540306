import { getUser } from '../modules/User/getUser';

const label = document.getElementById('admin-label');

if (label) {
  getUser().then(({ isAdmin }) => {
    if (isAdmin) {
      label.classList.remove('d-none');
    }
  });
}
