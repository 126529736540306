const ShawContract = window.ShawContract || {};


// Breakpoints
// const mobileBreakpoint = 576;
const tabletBreakpoint = 768;
// const laptopBreakpoint = 992;
const desktopBreakpoint = 1200;

function addRemoveLineClamps(cards, breakpoint = 0, lines = 'auto') {
  return function () {
    try {
      if (window.innerWidth >= breakpoint) {
        for (let i = 0; i < cards.length; i++) {
          $clamp(cards[i], { clamp: lines });
        }
      } else {
        for (let i = 0; i < cards.length; i++) {
          cards[i].setAttribute('style', '');
        }
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(e);
    }
  };
}

// higher order function
const addLineClamps = (selector, breakpoint, lines) => {
  const paragraphs = document.querySelectorAll(selector);

  if (paragraphs.length === 0) {
    return;
  }

  const clampFunction = addRemoveLineClamps(paragraphs, breakpoint, lines);
  window.addEventListener('DOMContentLoaded', clampFunction);
  window.addEventListener('resize', clampFunction);
  clampFunction();
};

// blog cards
addLineClamps('.blogs-wrapper .blog-item .description p', tabletBreakpoint, '100px');
// shared page product cards
addLineClamps('.product-boards-shared-page .product-card .card-text', desktopBreakpoint, 4);
// feature-list widget
addLineClamps('.feature-card .description', 0, 9);

ShawContract.addLineClamps = addLineClamps;

window.ShawContract = ShawContract;
