import { render, h } from 'preact';
import { cloneDeep } from 'lodash';
import { ContentItemTypes } from 'modules/Boards/Types';
import { AddContentWizard } from './Boards/AddContentWizard/AddContentWizard.jsx';
import { SectionGrid } from './Boards/SectionGrid';
import { BoardsAPI, logError } from '../modules';
import {
  onAddToBoardProduct, onAddToBoardContent, formatGoogleTagProductData, formatGoogleTagBMPaintData,
} from '../modules/GoogleTagManager';

function sendGoogleAnalyticsProductData(data, price) {
  if (data?.productContentItem?.productId) {
    const GoogleTagData = formatGoogleTagProductData(data, price);
    onAddToBoardProduct(GoogleTagData);
  } else {
    onAddToBoardContent(data);
  }
}

function sendGoogleAnalyticsProductsData(data) {
  const GoogleTagData = formatGoogleTagBMPaintData(data);
  onAddToBoardProduct(GoogleTagData);
}

export const SectionMethods = {
  appendItem(index, object, callback, sections, boardId, createRequestTypeCode, price) {
    const sectionIndex = index || 0;
    const grid = new SectionGrid(1, 3).setFromArray(sections[sectionIndex]?.contentItems);
    const [row, column] = grid.getPositionToAppend(object.contentItem);
 
    const dataToSend = {
      ...object,
      contentItem: {
        ...object.contentItem,
        row,
        column,
        sectionId: sections[sectionIndex]?.id,
      },
    };

    delete dataToSend.contentItem.coverPhotoStyleId;
    // While sending to the BoardService we are sending Photography  as an Article
    dataToSend.contentItem.type = dataToSend.contentItem.type === ContentItemTypes.photography ? ContentItemTypes.article : dataToSend.contentItem.type
    if (dataToSend.contentItem.type === 11) {
      dataToSend.contentItem.type = ContentItemTypes.product;
    }
    if(dataToSend.productContentItem)
    {
      var productStyleImages = []
      dataToSend.productContentItem.productStyleImages.map((item) => {
        productStyleImages = [...productStyleImages, {
            "ColorNumber": item.ColorNumber,
            "ColorName": item.ColorName,
            "ImageLink": item.ImageLink,
            "CoverImageLink": item.CoverImageLink,
            "Attributes": {
                "VarCode": item.Attributes?.VarCode
            }
        }]
      })
      dataToSend.productContentItem.productStyleImages = productStyleImages;
    }

    BoardsAPI.ContentItems.Create({ boardId, ...dataToSend })
      .then(() => {
        $('#AddContentWizard').modal('hide');
        const GTObject = { ...object, contentType: ContentItemTypes[object.contentItem.type] };
        sendGoogleAnalyticsProductData(GTObject, price);
        if (callback) {
          callback();
        }
      })
      .catch(logError);
  },

  appendItems(index, object) {
    const { contentItems: contentItemsArray, boardId, sections } = object;
    const sectionIndex = index || 0;
    const newSections = cloneDeep(sections);
    let contentItems = newSections[sectionIndex]?.contentItems;

    contentItems = new SectionGrid(1, 3)
      .setFromArray(contentItems)
      .appendItems(contentItemsArray, false)
      .getFlattenedArray();

    const contentItemsToSend = contentItems
      .slice(-contentItemsArray?.length)
      .map((contentItem) => ({
        ...contentItem,
        sectionId: newSections[sectionIndex]?.id,
      }));

    BoardsAPI.ContentItems.CreateMany({
      boardId,
      contentItems: contentItemsToSend,
    }).then(() => {
      $('#AddContentWizard').modal('hide');
      sendGoogleAnalyticsProductsData(object);
    }).catch(logError);
  },
};

function openModal(contentType, productType, productNumber, searchTerm, price, selectedColor, nodeAlias) {
  const container = document.getElementById('add-to-board-wizard');
  const addWizardState = [{
    step: 2,
    ProductType: productType,
    StyleNumber: productNumber,
    isOutsideBoard: true,
    title: searchTerm,
    contentType,
    price,
    selectedColor,
    nodeAlias,
  }, []];

  // the annonymous function is considered a new prop each time,
  // which is what let's us re-render it each time
  render(<AddContentWizard
    show={() => true}
    SectionMethods={SectionMethods}
    addWizardState={addWizardState}
  />, container, document.getElementById('AddContentWizard'));
}

const addListenersToOpenModal = (selector) => {
  const elems = document.querySelectorAll(selector);

  for (const elem of elems) {
    $(elem).on('click', openModal);
  }
};

export default {
  openModal,
  addListenersToOpenModal,
};
