const defaultValues = {
    id: undefined,
    name: undefined,
    variant: undefined,
    collectionName: undefined,
    category: undefined,
};

/**
 *
 * @param {{
  id: string,
  name: string,
  variant: string,
  collectionName: string,
  category: string,
  } | Event} data
 */
export function viewRoomScene(data, roomScene) {
    let eventData = data;
    const { currentTarget } = data;

    if (currentTarget) {
        eventData = $(currentTarget).data('gtm-event');
    }

    if (eventData) {
        dataLayer.push({
            event: 'view-room-scene',
            roomScene,
            id: eventData.id,
            name: eventData.name,
            variant: eventData.variant,
            collectionName: eventData.collectionName,
            category: eventData.category
        });
    }
}