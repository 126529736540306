export function toCamelCase(object) {
  let newO, origKey, newKey, value;

  const isObject = (someObject) => Object
    .prototype
    .toString
    .call(someObject) === '[object Object]' && someObject !== null;

  if (Array.isArray(object)) {
    return object.map(value => {
        if (typeof value === "object") {
          value = toCamelCase(value);
        }
        return value
    })
  } else if (isObject(object)) {
    newO = {}
    for (origKey in object) {
      if (object.hasOwnProperty(origKey)) {
        newKey = (origKey.charAt(0).toLowerCase() + origKey.slice(1) || origKey);
        value = object[origKey];
        if (Array.isArray(value) || isObject(value)) {
          value = toCamelCase(value);
        }
        newO[newKey] = value;
      }
    }
  }
  return newO;
}