import { roleTypes } from './roleTypes';

const { userObject } = ShawContract;

export const hasRole = (roleType) => userObject.KenticoRoles.some((role) => role === roleType);

export const isAdmin = () => hasRole(roleTypes.admin);
export const isInternal = () => hasRole(roleTypes.internal);
export const isDealer = () => hasRole(roleTypes.dealer);
export const isVideoCreator = () => hasRole(roleTypes.videoCreator);

export const canUploadOwnVideo = () => isInternal() || isVideoCreator();
export const canUploadBordCover = () => isInternal() || isAdmin();
