const defaultValues = {
  id: undefined,
  name: undefined,
  brand: 'shawcontract',
  category: undefined,
  variant: undefined,
  price: undefined,
  position: undefined,
  quantity: 1,
};

/**
 *
 * @param {{
  id: string,
  name: string,
  category: string,
  variant: string,
  price: string,
  list: string,
  position: string,
  quantity: number,
  } | Event} data
 */
export function addMultipleToCart({
  products,
  list,
  currencyCode = 'USD',
}) {
  dataLayer.push({
    event: 'add-to-cart',
    ecommerce: {
      currencyCode,
      add: {
        actionField: {
          list,
        },
        products: products.map((p) => ({
          ...defaultValues,
          ...p,
          dimension3: undefined,
          dimension4: undefined,
          dimension5: undefined,
          dimension6: undefined,
          metric1: undefined,
          metric4: undefined,
          metric14: undefined,
        })),
      },
    },
  });
}
