export const roleTypes = {
  admin: 'Admin',
  internal: 'Internal',
  dealer: 'Dealer',
  videoCreator: 'VideoCreator',
};

export const visibilityTypes = {
  internal: 0,
  dealer: 1,
  public: 2,
  videoCreator: 3,
};
