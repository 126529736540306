// old code that's used in design awards
// will need to be changed in the future

const checkIfAdminEndpoint = document.getElementById('checkIfAdminEndpoint')?.value;

export const getUser = () => new Promise((resolve, reject) => {
  const { user } = ShawContract;

  if (user) {
    resolve(user);
  } else if (checkIfAdminEndpoint) {
    $.ajax({
      url: checkIfAdminEndpoint,
      dataType: 'json',
      success: (res) => {
        ShawContract.user = res;
        resolve(res);
      },
      error: (error) => reject(error),
    });
  }
});
