/**
 * 
 * @param {{
 *  element: string,
 *  classes: string,
    attributes: {},
    text: string,
    appendTo: HTMLElement,
    shouldRender: Boolean,
    eventListeners: {},
    children: HTMLElement[],
  }}  obj 
 */
export function createElement(obj) {
  const {
    element = 'div',
    classes,
    attributes = {},
    text = '',
    appendTo,
    shouldRender = true,
    eventListeners = {},
    children = [],
  } = obj;

  if (!shouldRender) {
    return document.createDocumentFragment();
  }

  const el = document.createElement(element);
  el.textContent = text;

  if (typeof classes === 'string') {
    $(el).addClass(classes);
  } else if (Array.isArray(classes)) {
    classes.forEach((c) => {
      el.classList.add(c);
    });
  }

  if (appendTo) {
    appendTo.appendChild(el);
  }

  Object.keys(attributes).forEach((key) => el.setAttribute(key, attributes[key]));
  Object.keys(eventListeners).forEach((key) => el.addEventListener(key, eventListeners[key]));
  children.forEach((child) => el.appendChild(child));

  return el;
}
