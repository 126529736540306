const defaultValues = {
    id: undefined,
    name: undefined,
    variant: undefined,
    collectionName: undefined
};

/**
 *
 * @param {{
  id: string,
  name: string,
  variant: string,
  collectionName: string
  } | Event} data
 */
export function viewInstallationMethod(data, installMethod) {
    let eventData = data;
    const { currentTarget } = data;

    if (currentTarget) {
        eventData = $(currentTarget).data('gtm-event');
    }

    if (eventData) {
        dataLayer.push({
            event: 'view-install-method',
            installMethod,
            id: eventData.id,
            name: eventData.name,
            variant: eventData.variant,
            collectionName: eventData.collectionName
        });
    }
}