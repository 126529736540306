const defaultValues = {
  id: undefined,
  name: undefined,
  brand: 'shawcontract',
  category: undefined,
  variant: undefined,
  price: undefined,
  list: 'unfiltered',
  position: undefined,
};

/**
 *
 * @param {{
  id: string,
  name: string,
  category: string,
  variant: string,
  price: string,
  list: string,
  position: string,
  } | Event} data
 */
export function productImpression(data, currencyCode = 'USD') {
  let eventData = data;
  const { currentTarget } = data;

  if (currentTarget) {
    eventData = $(currentTarget).data('gtm-event');
  }

  if (eventData) {
    dataLayer.push({
      event: 'product-impression',
      ecommerce: {
        currencyCode,
        impressions: [{
          ...defaultValues,
          ...eventData,
          dimension3: undefined,
          dimension4: undefined,
          dimension5: undefined,
          dimension6: undefined,
          metric1: undefined,
          metric4: undefined,
          metric14: undefined,
        }],
      },
    });
  }
}
