function resetSubmenu() {
  $('.dropdown-submenu .dropdown-menu').removeClass('show right-side');
}

export function onSubmenuClick(e) {
  e.stopPropagation();
  e.preventDefault();

  const target = e.currentTarget;
  const parentDropdown = $(target).parents('.dropdown-menu').first();

  if (!$(target).next().hasClass('show')) {
    parentDropdown.find('.show')
      .removeClass('show');
  }
  const $subMenu = $(target).next('.dropdown-menu');
  $subMenu.toggleClass('show');

  const offsetRight = window.innerWidth - parentDropdown.get(0).getBoundingClientRect().right;

  setTimeout(() => {
    if (offsetRight < $subMenu.width()) {
      $subMenu.addClass('right-side');
    }
  }, 1);


  $(target).parents('.dropdown-menu').parent().on('hide.bs.dropdown', resetSubmenu);
}

$(document).ready(() => {
  $('.dropdown-menu .submenu-toggle').on('click', onSubmenuClick);
});
