const defaultValues = {
  id: undefined,
  name: undefined,
  brand: 'shawcontract',
  category: undefined,
  variant: undefined,
  price: undefined,
  position: undefined,
  quantity: 1,
};

/**
 *
 * @param {{
  id: string,
  name: string,
  category: string,
  variant: string,
  price: string,
  list: string,
  position: string,
  quantity: number,
  } | Event | list} data
 */
export function addToCart(data, currencyCode = 'USD') {
  let eventData = data;
  const { currentTarget } = data;

  if (currentTarget) {
    eventData = $(currentTarget).data('gtm-event');
  }
  const { list = 'unfiltered', position } = eventData;
  eventData = { ...eventData, category: eventData.category?.toLowerCase() };

  if (eventData) {
    dataLayer.push({
      event: 'add-to-cart',
      ecommerce: {
        currencyCode,
        add: {
          actionField: {
            list,
            position,
          },
          products: [{
            ...defaultValues,
            ...eventData,
            dimension3: undefined,
            dimension4: undefined,
            dimension5: undefined,
            dimension6: undefined,
            metric1: undefined,
            metric4: undefined,
            metric14: undefined,
          }],
        },
      },
    });
  }
}
