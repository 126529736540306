/**
 * @param {{
 *  step: number,
 *  option: string,
 *  products: Object[],
 *  currencyCode: string,
 * }} data
 */
export function checkoutStep(data) {
  const {
    step, products, currencyCode = 'USD',
  } = data;

  dataLayer.push({
    event: 'checkout-step',
    ecommerce: {
      currencyCode,
      checkout: {
        actionField: {
          step,
        },
        products,
      },
    },
  });
}

export function getCheckoutProductsData() {
  const productElements = document.querySelectorAll('.checkout-product-card, .checkout-samples-card');
  const productsArray = [];

  for (const product of productElements) {
    if (product.querySelector('[name=Quantity]')) {
      const quantity = parseInt(product.querySelector('[name=Quantity]').value, 10);
      let gtmData = $(product).data('gtm-event');
      gtmData = { ...gtmData, variant: gtmData.variant?.replace(/\(|\)/gi, '') };

      productsArray.push({
        ...gtmData,
        quantity,
        dimension3: undefined,
        dimension4: undefined,
        dimension5: undefined,
        dimension6: undefined,
        dimension24: undefined,
        dimension37: undefined,
        dimension38: gtmData?.backing,
        metric1: undefined,
        metric4: undefined,
        metric14: undefined,
      });
    }
  }

  return productsArray;
}


function calcRevenue(products, tax, shipping) {
  const revenue = products.reduce((acc, product) => acc + parseFloat(product.price || 0), 0)
  + parseFloat(tax)
  + parseFloat(shipping);

  if (revenue === 0) {
    return '0';
  }
  return revenue.toString();
}

/**
 * @param {{
 *  products: Object[],
 *  currencyCode: string,
 *  paymentType: string,
 *  transactionID, string,
 *  affiliation: string,
 *  revenue: string,
 *  shipping: string,
 *  tax: string,
 *  coupon: string,
 * }} data
 */
export function transaction(data) {
  const {
    products, currencyCode = 'USD', paymentType, id, affiliation = 'shawcontract', shipping = '0', tax = '0', coupon = '', shippingOption = undefined,
  } = data;

  const revenue = calcRevenue(products, tax, shipping);

  dataLayer.push({
    event: 'transaction',
    paymentType,
    ecommerce: {
      currencyCode,
      purchase: {
        actionField: {
          id,
          affiliation,
          revenue,
          shipping,
          tax,
          coupon,
        },
        products,
        dimension36: shippingOption,
      },
    },
  });
}
