const ShawContract = window.ShawContract || {};
let i18n = document.getElementById('commonTranslations') || {};
let i18nLayout = document.getElementById('layoutTranslations') || {};

i18n = i18n.value || '{}';
i18n = JSON.parse(i18n);

i18nLayout = i18nLayout.value || '{}';
i18nLayout = JSON.parse(i18nLayout);

ShawContract.i18n = { ...i18nLayout, ...i18n };
window.ShawContract = ShawContract;
