import { ContentItemTypes } from 'modules/Boards/Types';
import AddToMyBoards from '../components/addToMyBoards';

const addToBoardBtn = document.getElementById('pages-add-to-board');

function addPageToBoard() {
  const nodeAlias = location.pathname.toLowerCase();
  AddToMyBoards.openModal(ContentItemTypes.page, null, null, null, null, null, nodeAlias);
}

$(addToBoardBtn).on('click', addPageToBoard);
