import { h } from 'preact';
import {
  useRef, useEffect, useState, useCallback,
} from 'preact/hooks';

export const Modal = ({
  show = false,
  onOpen = () => {},
  onClose = () => {},
  classes = '',
  id = '',
  title = '',
  closeOnBackGroundClick = true,
  size = 'lg',
  showCloseInHeader = false,
  children,
}) => {
  const ref = useRef();
  const [isOpen, setIsOpen] = useState(show);

  const handleOpen = useCallback(() => {
    setIsOpen(true);
    onOpen();
  }, [onOpen]);
  const handleClose = useCallback(() => {
    setIsOpen(false);
    onClose();
  }, [onClose]);

  useEffect(() => {
    $(ref?.current).on('show.bs.modal', handleOpen);
    $(ref?.current).on('hidden.bs.modal', handleClose);
  }, [handleClose, handleOpen, onClose, onOpen]);

  useEffect(() => {
    if (show) {
      $(ref?.current).modal('show');
    } else {
      $(ref?.current).modal('hide');
    }
  }, [show]);

  return (
    <div id={id} class="modal fade modal-component" tabindex="-1" role="dialog" ref={ref} data-backdrop={closeOnBackGroundClick ? true : 'static'}>
      <div class={`modal-dialog modal-dialog-centered modal-${size} ${classes}`} role="document">
        <div class="modal-content">
          {title && (
            <div class="modal-header">
              <h2 class="modal-title">{title}</h2>
              {showCloseInHeader && <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              }
            </div>
          )}
          {isOpen && children}
        </div>
      </div>
    </div>
  );
};
