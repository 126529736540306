
const links = document.getElementsByTagName('a');
const currentDomainRegExp = new RegExp(`/${location.host}/`);

for (let i = 0; i < links.length; i++) {
  const a = links[i];
  const hasTargetAttribute = !!a.getAttribute('target');
  if (!currentDomainRegExp.test(a.href) && !hasTargetAttribute) {
    a.setAttribute('target', '_blank');
  }
}
