const getMap = (response) => {
  const map = {};
  const { UnavailableColors } = response;

  Object.keys(UnavailableColors).forEach((styleNumber) => {
    if (!map[styleNumber]) {
      map[styleNumber] = {};
    }

    UnavailableColors[styleNumber].forEach((color = {}) => {
      const { ColorNumber } = color;
      map[styleNumber][ColorNumber] = color?.IsUnavailable;
    });
  });

  return map;
};

export const CheckAvailability = (response = {}) => ({
  ...response,
  UnavailableColors: getMap(response),
});
