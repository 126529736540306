
/**
 * Normal images:
 *     Add class: 'lazyload'
 *     attribute: data-src instead of src
 * Carousel images:
 *     No class 'lazyload'
 *     attribute: data-src instead of src
 */

/**
* Bootstrap Carousel images don't need the class 'lazyload'
* instead the IntersectionObserver checks the element with the class 'carousel-item'
*
* After the first image in the carousel loads, it will start loading the images in the next slide as well.
* when the next slide becomes visible in the viewport, it will start loading the images in the 3rd slide, etc.
*/

import { productImpression } from '../modules/GoogleTagManager/productImpressions';


const rootContainerOptions = { rootMargin: '200px 0px' };

function lazyLoad(target) {
  const io = new IntersectionObserver(((entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const img = entry.target;

        if (img.hasAttribute('data-gtm-event')) {
          $(img).on('load', productImpression);
        }
        img.src = img.getAttribute('data-src');
        observer.disconnect();
      }
    });
  }), rootContainerOptions);

  io.observe(target);
  target.classList.remove('lazyload');
}


function lazyLoadCarouselSlide(target) {
  const io = new IntersectionObserver(((entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const slide = entry.target;
        const nextSlide = slide.nextElementSibling;
        const images = slide.querySelectorAll('img');

        images.forEach((img) => {
          const src = img.getAttribute('data-src');
          if (src) {
            img.setAttribute('src', src);
            img.removeAttribute('data-src');
          }
        });

        if (nextSlide) {
          const nextSlideImages = nextSlide.querySelectorAll('img');
          nextSlideImages.forEach((img) => {
            const src = img.getAttribute('data-src');
            if (src) {
              img.setAttribute('src', src);
              img.removeAttribute('data-src');
            }
          });
        }

        observer.disconnect();
      }
    });
  }), rootContainerOptions);

  io.observe(target);
}


const lazyLoadImages = () => {
  const images = document.querySelectorAll('.lazyload');
  const carouselSlides = document.querySelectorAll('.carousel-item');

  for (const img of images) {
    lazyLoad(img);
  }
  for (const slide of carouselSlides) {
    lazyLoadCarouselSlide(slide);
  }
};

lazyLoadImages();

// Add the function to the global scope under the namespace 'ShawContract'
const ShawContract = window.ShawContract || {};
ShawContract.lazyLoadImages = lazyLoadImages;
window.ShawContract = ShawContract;
