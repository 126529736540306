/* eslint-disable max-classes-per-file */
/* eslint-disable class-methods-use-this */
/**
 * map 'size' property -> columns width
 */
const ContentItemWidth = {
  0: 1,
  1: 2,
  2: 3,
};

class Cell {
  constructor(item) {
    this.value = item;
    this.width = ContentItemWidth[item.size];
    return this;
  }

  get isSmall() {
    return this.width === 1;
  }

  get isMedium() {
    return this.width === 2;
  }

  get isLarge() {
    return this.width === 3;
  }
}

export class SectionGrid {
  constructor(rows = 1, columns = 1) {
    this.grid = [];
    this.columnsLength = columns;

    for (let rowIndex = 0; rowIndex < rows; rowIndex++) {
      this.grid[rowIndex] = new Array(columns);
    }

    return this;
  }

  hasRow(rowIndex) {
    return !!this.grid[rowIndex];
  }

  isRowEmpty(rowIndex) {
    return !this.grid[rowIndex]?.some((i) => !!i);
  }

  isCellEmpty(rowIndex, colIndex) {
    return !!this.grid[rowIndex]?.[colIndex]?.value;
  }

  getById(id) {
    return this.getFlattenedArray().find((item) => item.id === id);
  }

  get(rowIndex, colIndex) {
    return this.grid[rowIndex]?.[colIndex];
  }

  getPositionById(id) {
    for (let i = 0; i < this.grid.length; i++) {
      const row = this.grid[i];
      for (let j = 0; j < row.length; j++) {
        const cell = row[j];

        if (cell?.value?.id === id) {
          return [i, j];
        }
      }
    }

    return [];
  }

  /**
     *
     * @param {*} rowIndex
     * @param {*} colIndex
     * @param {{width: number}} item Must have a width property
     */
  set(rowIndex, colIndex, item) {
    const newItem = new Cell(item);

    if (!this.hasRow(rowIndex)) {
      const newRow = new Array(this.columnsLength);
      newRow[colIndex] = newItem;
      this.addRowAt(rowIndex, newRow);
    } else {
      this.grid[rowIndex][colIndex] = newItem;
    }

    return this;
  }

  update(rowIndex, colIndex, value) {
    if (this.hasRow(rowIndex)) {
      const item = this.grid[rowIndex][colIndex];
      if (item) {
        this.grid[rowIndex][colIndex] = { ...item, ...value };
      }
    }
    return this;
  }

  remove(rowIndex, colIndex, shouldRemoveEmptyRow) {
    if (this.hasRow(rowIndex)) {
      this.grid[rowIndex][colIndex] = undefined;
    }

    if (shouldRemoveEmptyRow) {
      if (this.isRowEmpty(rowIndex)) {
        this.removeRowAt(rowIndex);
      }
    }

    return this;
  }

  removeById(id, shouldRemoveEmptyRow = true) {
    const { row: rowIndex, column: colIndex } = this.getById(id);

    if (this.hasRow(rowIndex)) {
      this.grid[rowIndex][colIndex] = undefined;
    }

    if (shouldRemoveEmptyRow && this.isRowEmpty(rowIndex)) {
      this.removeRowAt(rowIndex);
    }

    return this;
  }

  addRowAt(rowIndex, array = new Array(this.columnsLength)) {
    const lastRowIndex = this.grid.length;
    for (let i = lastRowIndex; i < rowIndex; i++) {
      this.grid.splice(i, 0, new Array(this.columnsLength));
    }

    this.grid.splice(rowIndex, 0, array);
    return this;
  }

  removeRowAt(rowIndex) {
    this.grid.splice(rowIndex, 1);
    return this;
  }

  getRowWidth(rowIndex) {
    if (Array.isArray(rowIndex)) {
      return rowIndex.filter(Boolean).reduce((acc, item) => acc + item?.width || 0, 0);
    }
    return this.grid[rowIndex]?.filter(Boolean).reduce((acc, item) => acc + item?.width || 0, 0);
  }

  indexOfLastItemInRow(rowIndex) {
    const lastColIndex = this.grid[rowIndex]?.length - 1;
    const itemIndex = this.grid[rowIndex].slice().reverse().findIndex((i) => i && i.value);

    if (itemIndex === -1) {
      return 0;
    }

    return lastColIndex - itemIndex;
  }

  transferToNewRow(rowIndex, fromColIndex) {
    const row = this.grid[rowIndex];
    this.addRowAt(rowIndex + 1);

    for (let i = fromColIndex; i < row.length; i++) {
      const item = row[i];
      this.remove(rowIndex, i)
        .set(rowIndex + 1, i, item);
    }

    return this;
  }

  forEach(callback) {
    for (let i = 0; i < this.grid.length; i++) {
      for (let j = 0; j < this.grid[i].length; j++) {
        const element = this.grid[i][j];

        if (element) {
          callback(element, [i, j], this.grid);
        }
      }
    }
  }

  removeEmptyRows() {
    this.grid = this.grid.filter((row, i) => !this.isRowEmpty(i));
    return this;
  }

  checkForCollision(rowIndex, colIndex, width = 1) {
    const row = this.grid[rowIndex];
    const endIndex = colIndex + width;
    const rowWidth = this.getRowWidth(rowIndex);

    if (rowWidth + width > this.columnsLength) {
      return true;
    }

    for (let i = colIndex; i < endIndex; i++) {
      const hasItem = !!row[i];

      if (hasItem) {
        return true;
      }
    }
    return false;
  }

  getPositionToAppend(item) {
    const lastRowIndex = this.grid.length - 1;
    const lastItemColIndex = this.indexOfLastItemInRow(lastRowIndex);
    const lastItemWidth = this.get(lastRowIndex, lastItemColIndex)?.width || 0;
    const width = ContentItemWidth[item.size];
    let row = lastRowIndex;
    let col = lastItemColIndex + lastItemWidth;

    if (this.checkForCollision(lastRowIndex, col, width)) {
      row += 1;
      col = 0;
    }

    return [row, col];
  }

  appendItem(item) {
    const [row, col] = this.getPositionToAppend(item);
    this.set(row, col, item);

    return this;
  }

  appendItems(array, keepColumnPosition = true) {
    if (!Array.isArray(array)) {
      return this;
    }

    if (keepColumnPosition) {
      const numberOfRows = this.grid.length;
      array.forEach((item) => {
        const { row, column } = item;
        const newRowIndex = numberOfRows + row;
        this.set(newRowIndex, column, item);
      });
    } else {
      array.forEach((item) => {
        this.appendItem(item);
      });
    }

    return this;
  }

  removeItems(items) {
    if (Array.isArray(items)) {
      items.forEach((item) => {
        const { row, column } = item;
        this.grid[row][column] = undefined;
      });

      this.removeEmptyRows();
    }
    return this;
  }

  resize(id, newSize) {
    const [rowIndex, colIndex] = this.getPositionById(id);
    const item = this.get(rowIndex, colIndex)?.value;
    const itemWithNewSize = item && { ...item, size: newSize };

    if (rowIndex !== undefined && colIndex !== undefined) {
      this.remove(rowIndex, colIndex, false);
      this.addToRow(rowIndex, colIndex, itemWithNewSize);
    }

    return this;
  }

  createNewCells(cell) {
    const array = new Array(this.columnsLength);
    array[0] = cell;
    return array.splice(0, cell.width);
  }

  moveLargeCellsToRowStart(row) {
    const newRow = [...row];
    const index = newRow.findIndex((cell) => cell?.isLarge);

    if (index !== -1) {
      const temp = row[index];
      newRow[index] = undefined;
      newRow[0] = temp;
    }

    return newRow;
  }

  isRowOverflowing(row) {
    return this.getRowWidth(row) > this.columnsLength;
  }

  sanitizeRow(row) {
    return row
      .filter(Boolean)
      .map((cell) => this.createNewCells(cell))
      .reduce((acc, val) => acc.concat(val), []);
  }

  getRowOverflow(row) {
    let row1 = new Array(this.columnsLength);
    let row2 = new Array(this.columnsLength);
    let counter = 0;

    for (let i = 0; i < row.length; i++) {
      counter += row[i]?.width || 0;
      if (counter > this.columnsLength) {
        row.splice(i).forEach((cell) => {
          row2[cell?.value?.column] = cell;
        });
        row.forEach((cell, j) => { row1[j] = cell; });
        break;
      }
    }

    if (counter <= this.columnsLength) {
      row1 = row;
    }

    if (row1.length > this.columnsLength) {
      if (!row1[0]) {
        row1.shift();
      }

      if (row1[2]?.isMedium && !row1[1]) {
        row1.splice(1, 1);
      }
    }

    if (row2[2]?.isMedium && !row2[1]) {
      row2.splice(1, 1);
    }

    if (row1[0]?.isMedium && row1[1]?.isSmall) {
      row1.splice(1, 0, undefined);
    }

    row1 = row1.splice(0, this.columnsLength);
    row1 = this.moveLargeCellsToRowStart(row1);
    row2 = this.moveLargeCellsToRowStart(row2);

    if (this.isRowOverflowing(row2)) {
      return [row1, ...this.getRowOverflow(row2)];
    }
    return [row1, row2];
  }

  addToRow(rowIndex, colIndex, item) {
    const row = this.grid[rowIndex];
    const newCell = new Cell(item);
    const subArray = this.createNewCells(newCell);

    if (newCell.width === this.columnsLength) {
      row.splice(0, 0, newCell);
    } else if (this.checkForCollision(rowIndex, colIndex, newCell.width)) {
      row.splice(colIndex, 0, newCell);
    } else {
      row.splice(colIndex, newCell.width, ...subArray);
    }

    const rows = this.getRowOverflow(row);
    this.removeRowAt(rowIndex);
    rows.forEach((newRow, i) => this.addRowAt(rowIndex + i, newRow));
    this.removeEmptyRows();

    return this;
  }

  swap(rowIndex, col1, col2) {
    const row = this.grid[rowIndex];
    const item1 = this.get(rowIndex, col1);
    const item2 = this.get(rowIndex, col2);
    const { width: item1Width = 1 } = item1 || {};
    const { width: item2Width = 1 } = item2 || {};

    if (item1Width === item2Width) {
      row[col1] = item2;
      row[col2] = item1;
    } else {
      const isFirstElementMedium = row[0]?.isMedium && this.getRowWidth(rowIndex) < this.columnsLength;
      const initialEmptyRow = isFirstElementMedium ? [0] : [];

      this.grid[rowIndex] = row.filter(Boolean).reverse().reduce((acc, value) => {
        acc.push(...this.createNewCells(value));
        return acc;
      }, initialEmptyRow);
    }

    return this;
  }

  syncPositions() {
    this.forEach((cell, [row, column]) => {
      if (cell?.value) {
        const updatedItem = { value: { ...cell.value, row, column } };
        this.update(row, column, updatedItem);
      }
    });
  }

  getArrayWithEmptySpaces() {
    this.syncPositions();
    const arr = [];

    for (let i = 0; i < this.grid.length; i++) {
      const row = this.grid[i];
      for (let j = 0; j < row.length;) {
        const cell = row[j];

        if (cell?.value) {
          const { size = 1 } = cell.value;
          arr.push(cell.value);
          j += size + 1;
        } else {
          arr.push({
            row: i, column: j, size: 0, isEmpty: true,
          });
          j++;
        }
      }
    }

    return arr;
  }

  getGridWithEmptySpaces() {
    this.syncPositions();
    const grid = [...this.grid];

    for (let i = 0; i < this.grid.length; i++) {
      const row = this.grid[i];
      for (let j = 0; j < row.length;) {
        const cell = row[j];

        const item = cell?.value || {
          row: i, column: j, size: 0, isEmpty: true,
        };
        grid[i][j] = item;

        if (cell?.value) {
          const { size = 1 } = cell.value;
          j += size + 1;
        } else { j++; }
      }
    }

    return grid;
  }

  setFromArray(array) {
    if (Array.isArray(array)) {
      array.forEach((item) => {
        const { row, column } = item;
        this.set(row, column, item);
      });
    }

    return this;
  }

  getFlattenedArray() {
    this.syncPositions();
    return this.grid.flat().filter((i) => i && i.value).map(({ value }) => value);
  }
}
