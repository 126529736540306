const ShawContract = window.ShawContract || {};

window.toastr.options = {
  closeButton: false,
  debug: false,
  newestOnTop: true,
  progressBar: false,
  positionClass: 'toast-top-right',
  preventDuplicates: false,
  onclick: null,
  showDuration: '300',
  hideDuration: '1000',
  timeOut: '5000',
  extendedTimeOut: '1000',
  showEasing: 'swing',
  hideEasing: 'linear',
  showMethod: 'fadeIn',
  hideMethod: 'fadeOut',
};

ShawContract.addSuccessNotificationToElement = function addSuccessNotificationToElement(elem, title, description, options) {
  if (!elem) {
    return;
  }

  if (elem.length) {
    for (let i = 0; i < elem.length; i++) {
      $(elem[i]).on('click', () => {
        window.toastr.success(description, title, options);
      });
    }
  } else {
    $(elem).on('click', () => {
      window.toastr.success(description, title, options);
    });
  }
};

window.ShawContract = ShawContract;
