const ShawContract = window.ShawContract || {};

ShawContract.setTimestampsToLocalTime = function setTimestampsToLocalTime() {
  const timestamps = document.querySelectorAll('.server-timestamp');
  if (!timestamps) {
    return;
  }

  for (let i = 0; i < timestamps.length; i++) {
    const element = timestamps[i];
    const date = new Date(element.textContent.trim());
    const localFormatedDate = date.toLocaleString();
    element.textContent = localFormatedDate;
    element.classList.remove('server-timestamp');
  }
};

ShawContract.setTimestampsToLocalTime();

window.ShawContract = ShawContract;
