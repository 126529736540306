import Cookies from 'js-cookie';
import base64 from 'base-64';
import { toCamelCase } from 'modules/ReusableMethods';
import { utility } from '../utility';
import { redirectToSignIn } from '../../critical/loginRedirection';
import { showSaveNotification } from '../showNotification';

const getTokenEndpoint = `/${utility.getPageCulture()}/account/GetNewAadToken`;

const delay = (retryCount) => new Promise((resolve) => setTimeout(resolve, 10 ** (retryCount + 1)));

export class Endpoint {
  constructor(method = '', endpoint, baseUrl) {
    this.authToken = null;
    this.tokenExpirationTime = null;
    this.method = method.toUpperCase();
    this.baseUrl = baseUrl;
    this.endpoint = endpoint;
    this.options = {
      headers: {},
    };
    this.requestData = null;
    this.isContentTypeJSON = false;
    this._useExponentialBackoff = false;
    this._shouldDenormalize = false;
    this._isCamelCase = false;

    return this;
  }

  contentType(string) {
    this.options.contentType = string;
    return this;
  }

  json() {
    this.isContentTypeJSON = true;
    this.options.contentType = 'application/json';
    return this;
  }

  formData() {
    this._isFormDataType = true;

    // https://stackoverflow.com/questions/6974684/how-to-send-formdata-objects-with-ajax-requests-in-jquery
    this.options = {
      ...this.options,
      processData: false,
      contentType: false,
    };
    return this;
  }

  msg(msg) {
    this.shouldShowToastr = true;
    this.notificationMsg = msg;
    return this;
  }

  enableExponentialBackoff() {
    this._useExponentialBackoff = true;
  }

  denormalize(callback) {
    this._shouldDenormalize = true;
    this._denormCallback = callback;

    return this;
  }

  _handleToastrMsg() {
    if (this.shouldShowToastr) {
      showSaveNotification(this.notificationMsg);
    }
  }

  _handleDenormalize(obj) {
    if (this._shouldDenormalize) {
      return this._denormCallback(obj);
    }
    return obj;
  }

  _handleFormData() {
    const formData = new FormData();

    Object.keys(this.requestData).forEach((key) => formData.append(key, this.requestData[key]));

    return formData;
  }

  camelCase() {
    this._isCamelCase = true;
    return this;
  }

  _handleCamelCase(response) {
    return this._isCamelCase ? toCamelCase(response) : response;
  }

  _responseTransformers(response) {
    let res = response;

    res = this._handleDenormalize(res);
    res = this._handleCamelCase(res);

    return res;
  }

  _formatRequestData(data) {
    this.requestData = data;
    let url = this.baseUrl + this.endpoint;
    let requestData;

    if (this.isContentTypeJSON) {
      requestData = JSON.stringify(data);
    } else if (this._isFormDataType) {
      requestData = this._handleFormData();
    } else {
      const params = $.param(data);
      url = `${url}?${params}`;
    }

    return { url, requestData };
  }

  auth(isPrivate = true) {
    if (isPrivate) {
      this.requiresAuth = true;
      const base64Token = Cookies.get('Aad') || '';
      const token = base64.decode(base64Token);

      if (token) {
        const { AccessToken, ExpirationDate } = JSON.parse(token);
        this.authToken = AccessToken;
        this.tokenExpirationTime = new Date(ExpirationDate);
        this.options.headers.Authorization = `Bearer ${this.authToken}`;
      }
    }
    return this;
  }

  _exponentialBackoff(request, retryCount = 0, limit = 5, lastError) {
    if (retryCount > limit) {
      throw lastError;
    }
    return request().catch((e) => delay(retryCount)
      .then(() => this._exponentialBackoff(request, retryCount + 1, limit, e)));
  }

  _getNewToken(err) {
    return new Promise((resolve, reject) => {
      // check if unauthorized token

      // request new token
      $.ajax({
        url: getTokenEndpoint,
        context: this,
        cache: false,
        success: () => {
          this.auth();
          this.apiCall(this.requestData)()
            .then((res) => resolve(this._responseTransformers(res)))
            .catch((error) => reject(new Error({ error })));
        },
        error: redirectToSignIn,
      });
    });
  }

  _checkAuthentication() {
    return new Promise((resolve, reject) => {
      if (this.requiresAuth) {
        if (!this.tokenExpirationTime || this.tokenExpirationTime.getTime() < new Date().getTime()) {
          reject();
        } else {
          resolve();
        }
      } else {
        resolve();
      }
    });
  }

  apiCall(data) {
    return () => {
      const { method, options } = this;
      const { requestData, url } = this._formatRequestData(data);

      return new Promise((resolve, reject) => {
        this._checkAuthentication()
          .then(() => {
            $.ajax({
              url,
              method,
              data: requestData,
              context: this,
              success: (res) => {
                this._handleToastrMsg();
                resolve(this._responseTransformers(res));
              },
              statusCode: {
                401: (err) => this._getNewToken(err).then(resolve).catch(reject),
                409: (res) => reject(res.status),
              },
              ...options,
            });
          })
          .catch(() => {
            this._getNewToken().then(resolve).catch(reject);
          });
      });
    };
  }

  getAjax() {
    return (data) => {
      if (this._useExponentialBackoff) {
        return this._exponentialBackoff(this.apiCall(data));
      }
      return this.apiCall(data)();
    };
  }
}
