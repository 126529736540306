
const { i18n } = ShawContract;

if ($ !== undefined) {
  const { validator } = $;
  // regex for international characters
  $.__nameRegex = /^[\.\-,'A-Za-z\u00C0-\u1FFF\u2800-\uFFFDÆÐƎƏƐƔĲŊŒẞÞǷȜæðǝəɛɣĳŋœĸſßþƿȝĄƁÇĐƊĘĦĮƘŁØƠŞȘŢȚŦŲƯY̨Ƴąɓçđɗęħįƙłøơşșţțŧųưy̨ƴÁÀÂÄǍĂĀÃÅǺĄÆǼǢƁĆĊĈČÇĎḌĐƊÐÉÈĖÊËĚĔĒĘẸƎƏƐĠĜǦĞĢƔáàâäǎăāãåǻąæǽǣɓćċĉčçďḍđɗðéèėêëěĕēęẹǝəɛġĝǧğģɣĤḤĦIÍÌİÎÏǏĬĪĨĮỊĲĴĶƘĹĻŁĽĿʼNŃN̈ŇÑŅŊÓÒÔÖǑŎŌÕŐỌØǾƠŒĥḥħıíìiîïǐĭīĩįịĳĵķƙĸĺļłľŀŉńn̈ňñņŋóòôöǒŏōõőọøǿơœŔŘŖŚŜŠŞȘṢẞŤŢṬŦÞÚÙÛÜǓŬŪŨŰŮŲỤƯẂẀŴẄǷÝỲŶŸȲỸƳŹŻŽẒŕřŗſśŝšşșṣßťţṭŧþúùûüǔŭūũűůųụưẃẁŵẅƿýỳŷÿȳỹƴźżžẓ\s]+$/g;

  // US phone numbers
  $.__phoneRegex = /^(1[\s\-\.]?)?((\([2-9][0-9]{2}\))|[2-9][0-9]{2})[\s\-\.]?[\0-9]{3}[\s\-\.]?[0-9]{4}$/g;

  /**
     * Adding a new method for regex validation to the jquery library
     */
  validator.addMethod('regex', function (value, element, paramObj) {
    const param = paramObj.value;
    const { name } = paramObj;

    if (name === 'name') {
      validator.messages.regex = i18n.InvalidName || "Only letters and the following symbols: -,'.  are accepted.";
    } else {
      validator.messages.regex = i18n.PhoneAllowedSymbols || 'Please enter a value in the correct format.';
    }

    return (
      this.optional(element)
            || value.match(typeof param === 'string' ? new RegExp(param) : param)
    );
  });

  /**
     * Override error messages
     */
  $.extend(validator.messages, {
    required: i18n.Required || 'This field is required',
    minlength: i18n.MinLength || 'Please enter at least {0} characters',
    maxlength: i18n.MaxLength || 'Maximum character length: {0}',
    email: i18n.InvalidEmail || 'Please eneter a valid email address',
    number: i18n.Number || 'Only numbers are allowed',
  });
}
