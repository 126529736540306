const defaultValues = {
  id: undefined,
  name: undefined,
  brand: 'shawcontract',
  category: undefined,
  variant: undefined,
  price: undefined,
};

/**
 *
 * @param {{
  id: string,
  name: string,
  category: string,
  variant: string,
  price: string,
  } | Event} data
 */
export function productDetails(data, currencyCode = 'USD') {
  const { currentTarget } = data;
  let eventData = data;

  if (currentTarget) {
    eventData = $(currentTarget).data('gtm-event');
  }
  eventData = { ...eventData, category: eventData.category?.toLowerCase() };

  if (eventData) {
    dataLayer.push({
      event: 'product-detail-view',
      ecommerce: {
        currencyCode,
        detail: {
          products: [{
            dimension3: undefined,
            dimension4: undefined,
            dimension5: undefined,
            dimension6: undefined,
            metric1: undefined,
            metric4: undefined,
            metric14: undefined,
            ...defaultValues,
            ...eventData,
          }],
        },
      },
    });
  }
}
