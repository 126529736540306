import { boardShareLink as GTMboardShareLink } from 'modules/GoogleTagManager/board';
import { utility } from '../modules/utility';

const ShawContract = window.ShawContract || {};
const { i18n } = ShawContract;

const shareBoardModal = document.getElementById('share-board-modal');
const copyLinkBtn = document.getElementById('copyLinkBtn');
const shareLinkSwitch = document.getElementById('shareBoardLinkSwitch');
const usersWithAccess = document.getElementById('users-with-access');
const invitePeople = document.getElementById('invite-people');
const invitedPeopleWrapper = document.getElementById('users-with-access');
const errorNoPeople = document.getElementById('error-no-people');
const errorEmailContent = document.getElementById('error-email-content');
const errorNoSubject = document.getElementById('error-no-subject');
const sendInviteBtn = document.getElementById('send-invite-btn');
const saveBoardBtn = document.getElementById('save-board-btn');
const stopSharingButton = document.getElementById('stop-sharing');
const subject = document.getElementById('subject');
const emailNote = document.getElementById('emailNote');

let board = {};

function resetInputFields() {
  subject.value = '';
  emailNote.value = '';
  invitePeople.value = '';
  errorNoPeople?.classList.remove('d-block');
  errorEmailContent?.classList.remove('d-block');
  errorNoSubject?.classList.remove('d-block');
}

function resetModal() {
  // utility.removeChildren(invitedPeopleWrapper);
  board = {};
  resetInputFields();
  $('#copyBoardLink').val('');
  $('#board-title').text('');
}

function toggleShareLink() {
  // const isEnabled = shareLinkSwitch?.checked || true;
  // board.IsPrivate = !isEnabled;
  // const enabledLabel = $('#switchEnableLabel');
  // const disabledLabel = $('#switchDisableLabel');

  // if (isEnabled) {
  //   enabledLabel.show();
  //   disabledLabel.hide();
  //   copyLinkBtn.classList.remove('copy-disabled');
  //   usersWithAccess.children.forEach((user) => {
  //     user.getElementsByTagName('button')[0].classList.add('btn-disabled');
  //   });
  // } else {
  //   disabledLabel.show();
  //   enabledLabel.hide();
  //   copyLinkBtn.classList.add('copy-disabled');
  //   usersWithAccess.children.forEach((user) => {
  //     user.getElementsByTagName('button')[0].classList.remove('btn-disabled');
  //   });
  // }
}

function createPeopleWithAccessList(users) {
  if (users.length === 0) {
    $('#people-with-access-count').text(`( ${board.InvitedUsers.length} )`);
    return;
  }

  users.forEach((user) => {
    let el = null;
    if (user.Email.toLowerCase() === board.UserId.toLowerCase()) {
      el = `
        <li class="d-flex justify-content-between py-3 bordered-list-item">
          <span class="d-flex flex-column">
            <strong class="text-size-small">${user.Name} (${i18n.ShareBoardModalOwner})</strong>
            <span class="text-muted text-size-xsmall">${user.Email}</span>
          </span>
          <button class="btn-text-danger regular pr-3 btn-disabled d-none" data-user-id="${user.ID}">${i18n.RemoveAccess}</button>
        </li>`;
    } else {
      el = `
        <li class="d-flex justify-content-between py-3 bordered-list-item">
          <span class="d-flex flex-column">
            <strong class="text-size-small">${user.Name}</strong>
            <span class="text-muted text-size-xsmall">${user.Email}</span>
          </span>
          <button class="btn-text-danger regular pr-3 btn-disabled" data-user-id="${user.ID}">${i18n.RemoveAccess}</button>
        </li>`;
    }

    invitedPeopleWrapper.insertAdjacentHTML('beforeend', el);
  });
  invitedPeopleWrapper.getElementsByTagName('li').forEach((element) => {
    element.getElementsByTagName('button')[0].addEventListener('click', removeAccess);
  });
  $('#people-with-access-count').text(`( ${board.InvitedUsers.length} )`);
  toggleShareLink();
}

function stopSharing() {
  const url = $('#stop-sharing-url').val();
  $.ajax({
    url,
    type: 'POST',
    contentType: 'application/json',
    data: JSON.stringify({ boardId: board.ID }),
    success(data) {
      utility.removeChildren(invitedPeopleWrapper);
      board = data.board;
      shareLinkSwitch.checked = !board.IsPrivate;
      createPeopleWithAccessList(board.InvitedUsers);
    },
    error(error) {
      // eslint-disable-next-line no-console
      console.error(error);
    },
  });
}

function removeAccess(event) {
  event.preventDefault();
  const userId = event.target.getAttribute('data-user-id');
  const url = $('#remove-access-url').val();
  const data = {
    boardId: board.ID,
    userId,
  };
  $.ajax({
    url,
    type: 'POST',
    contentType: 'application/json',
    data: JSON.stringify(data),
    success(data) {
      utility.removeChildren(invitedPeopleWrapper);
      board = data.currentBoard;
      createPeopleWithAccessList(board.InvitedUsers);
    },
    error(error) {
      // eslint-disable-next-line no-console
      console.error(error);
    },
  });
}

function sendInvite() {
  if (invitePeople.value === '') {
    errorNoPeople.classList.add('d-block');
  } else if (!utility.validateEmails(invitePeople.value)) {
    errorNoPeople.classList.remove('d-block');
    errorEmailContent.classList.add('d-block');
  } else if (subject.value.trim() === '') {
    errorNoSubject.classList.add('d-block');
    errorNoPeople.classList.remove('d-block');
    errorEmailContent.classList.remove('d-block');
  } else {
    errorNoPeople.classList.remove('d-block');
    errorEmailContent.classList.remove('d-block');
    errorNoSubject.classList.remove('d-block');
    const data = {
      boardId: board.ID,
      Recipients: invitePeople.value,
      EmailNote: emailNote.value,
      subject: subject.value,
    };
    const url = $('#send-board-invite').val();

    $.ajax({
      url,
      type: 'POST',
      contentType: 'application/json',
      data: JSON.stringify(data),
      beforeSend() {
        sendInviteBtn.setAttribute('disabled', '');
        $('#spinner-wrapper').show();
      },
      complete() {
        $('#spinner-wrapper').hide();
        sendInviteBtn.removeAttribute('disabled');
      },
      success(data) {
        window.toastr.success('', i18n['SharedBoard.Share.SuccessMsgTitle']);
        // board = data;
        // utility.removeChildren(invitedPeopleWrapper);
        resetInputFields();
        $(shareBoardModal).modal('hide');
        // createPeopleWithAccessList(board.InvitedUsers);
      },
      error(error) {
        // eslint-disable-next-line no-console
        console.error(error);
      },
    });
  }
}

function saveBoardSettings() {
  const url = $('#save-changes-url').val();
  $.ajax({
    url,
    type: 'POST',
    contentType: 'application/json',
    data: JSON.stringify({ board }),
    success() {
      $(shareBoardModal).modal('hide');
      $('#board-privacy').val(board.IsPrivate);
      window.toastr.success('', i18n['SharedBoard.Share.SuccessMsgTitle']);
    },
    error(error) {
      // eslint-disable-next-line no-console
      console.error(error);
    },
  });
}

function getBoardData() {
  const url = $('#get-board-endpoint').val();
  const editboardId = document.getElementById('edit-board-id')?.value;
  const boardId = editboardId || shareBoardModal.querySelector('#transfer-board-id')?.value;
  board.ID = boardId;

  $.ajax({
    url,
    type: 'GET',
    dataType: 'JSON',
    data: { boardId },
    async: true,
    cache: false,
    success(data) {
      $('#copyBoardLink').val(data?.BoardUrl);
      const { Board } = data;
      if (Board) {
        board = Board;
        $('#board-title').text(`"${board.BoardName}"`);
        // shareLinkSwitch.checked = !board.IsPrivate;
        toggleShareLink();
        createPeopleWithAccessList(board.InvitedUsers);
      }
    },
    error(error) {
      // eslint-disable-next-line no-console
      console.error(error);
    },
  });
}

ShawContract.addShareBoardBtnEvents = () => {
  const shareBtn = document.getElementById('shareThisBoard');
  $(shareBtn).on('click', () => {
    const boardId = document.getElementById('board-id').value;
    shareBoardModal.querySelector('#transfer-board-id').value = boardId;
    $(shareBoardModal).modal('show');
  });
};

$('#share-board-modal').on('show.bs.modal', getBoardData);
$('#share-board-modal').on('hidden.bs.modal', resetModal);
$(stopSharingButton).on('click', stopSharing);
ShawContract.addSuccessNotificationToElement(copyLinkBtn, i18n['SharedBoard.Share.SuccessMsg']);
$(shareLinkSwitch).on('change', toggleShareLink);
$(sendInviteBtn).on('click', sendInvite);
$(saveBoardBtn).on('click', saveBoardSettings);
$(copyLinkBtn).on('click', () => {
  const url = $('#copyBoardLink').val();
  GTMboardShareLink(board.ID);
  utility.copyToClipboard(url, shareBoardModal);
});

ShawContract.addShareBoardBtnEvents();
window.ShawContract = ShawContract;
