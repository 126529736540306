const socialLinkElements = document.getElementsByClassName('gtm-social-link');

for (const link of socialLinkElements) {
  $(link).on('click', ({ currentTarget }) => {
    const eventData = $(currentTarget).data('gtm-event');

    dataLayer.push({
      event: 'social-share-link',
      ...eventData,
    });
  });
}
