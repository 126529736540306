import { ContentItemTypes } from 'modules/Boards/Types';
import { canUploadOwnVideo } from 'modules/User';

export const step1Data = {
  product: {
    label: 'ContentWizard.Product.Title',
    description: 'ContentWizard.Product.Description',
    className: 'product-icon',
    types: [ContentItemTypes.product, ContentItemTypes.paint, ContentItemTypes.customPattern],
    defaultType: ContentItemTypes.product,
    hasPermissions: true,
  },
  collection: {
    label: 'ContentWizard.Collection.Title',
    description: 'ContentWizard.Collection.Description',
    className: 'collection-icon',
    types: [ContentItemTypes.collection],
    searchKey: 'Collection',
    defaultType: ContentItemTypes.collection,
    hasPermissions: true,
  },
  page: {
    label: 'ContentWizard.WebPage.Title',
    description: 'ContentWizard.WebPage.Description',
    className: 'web-icon',
    type: [ContentItemTypes.page],
    searchKey: 'GenericTechnicalPage',
    action: 'setPage',
    defaultType: ContentItemTypes.page,
    hasPermissions: true,
  },
  resources: {
    label: 'ContentWizard.Resources',
    description: 'ContentWizard.Resources.Description',
    className: 'other-icon',
    types: [ContentItemTypes.article, ContentItemTypes.pdf, ContentItemTypes.video],
    defaultType: ContentItemTypes.article,
    hasPermissions: true,
  },
  ownVideo: {
    label: 'ContentWizard.UploadDoc.Title',
    description: 'ContentWizard.UploadDoc.Description',
    className: 'multimedia-icon',
    type: [ContentItemTypes.ownVideo],
    searchKey: 'OwnVideo',
    defaultType: ContentItemTypes.ownVideo,
    hasPermissions: canUploadOwnVideo(),
  },
  // ownVideo: {
  //   label: 'ContentWizard.UploadVideo.Title',
  //   description: 'ContentWizard.UploadVideo.Description',
  //   className: 'own-video-icon',
  //   type: [ContentItemTypes.ownVideo],
  //   searchKey: 'OwnVideo',
  //   defaultType: ContentItemTypes.ownVideo,
  //   hasPermissions: canUploadOwnVideo(),
  // },
  brand: {
    label: 'ContentWizard.Brand.Title',
    description: 'ContentWizard.Brand.Description',
    className: 'brand-icon',
    types: [ContentItemTypes.product, ContentItemTypes.paint],
    defaultType: ContentItemTypes.brand,
    hasPermissions: true,
  }
};

// eslint-disable-next-line consistent-return
export const mapToStep1Type = (contentType) => {
  switch (contentType) {
    case ContentItemTypes.product:
    case ContentItemTypes.paint:
      return 'product';
    case ContentItemTypes.collection:
      return 'collection';
    case ContentItemTypes.article:
    case ContentItemTypes.pdf:
    case ContentItemTypes.video:
    case ContentItemTypes.other:
    case ContentItemTypes.photography:
      return 'resources';
    case ContentItemTypes.ownVideo:
      return 'ownVideo';
    case ContentItemTypes.page:
      return 'page';
    case ContentItemTypes.brand:
      return 'brand';
    default:
      // eslint-disable-next-line no-console
      console.error('unhandled type');
      break;
  }
};

export const getStep1TypeData = (contentType) => step1Data[mapToStep1Type(contentType)];

const addArticleTypeStrings = {
  configuration: 'ContentWizard.Label.BlogConfiguration',
  addItem: 'ContentWizard.Label.AddBlog',
  searchPlaceholder: 'ContentWizard.Label.AddBlog.Placeholder',
  itemSize: 'ContentWizard.Label.BlogItemSize',
  itemDescription: 'ContentWizard.Label.BlogDescr',
  descrPlaceholder: 'ContentWizard.Label.BlogDescr.Placeholder',
};

const addVideoTypeStrings = {
  configuration: 'ContentWizard.Label.VideoConfiguration',
  addItem: 'ContentWizard.Label.AddVideo',
  searchPlaceholder: 'ContentWizard.Label.AddVideo.Placeholder',
  itemSize: 'ContentWizard.Label.VideoItemSize',
  itemDescription: 'ContentWizard.Label.VideoDescr',
  descrPlaceholder: 'ContentWizard.Label.VideoDescr.Placeholder',
};

const addCollectionTypeString = {
  configuration: 'ContentWizard.Label.CollectionConfiguration',
  addItem: 'ContentWizard.Label.AddCollection',
  searchPlaceholder: 'ContentWizard.Label.AddCollection.Placeholder',
  itemSize: 'ContentWizard.Label.CollectionItemSize',
  itemDescription: 'ContentWizard.Label.CollectionDescr',
  descrPlaceholder: 'ContentWizard.Label.CollectionDescr.Placeholder',
};

const addPdfTypeString = {
  configuration: 'ContentWizard.Label.PdfConfiguration',
  addItem: 'ContentWizard.Label.AddPdf',
  searchPlaceholder: 'ContentWizard.Label.AddPdf.Placeholder',
  itemSize: 'ContentWizard.Label.PdfItemSize',
  itemDescription: 'ContentWizard.Label.PdfDescr',
  descrPlaceholder: 'ContentWizard.Label.PdfDescr.Placeholder',
};

const addOtherTypeString = {
  configuration: 'ContentWizard.Label.OtherConfiguration',
  addItem: 'ContentWizard.Label.AddContent',
  searchPlaceholder: 'ContentWizard.Label.AddContent.Placeholder',
  itemSize: 'ContentWizard.Label.ContentSize',
  itemDescription: 'ContentWizard.Label.ContentDescription',
  descrPlaceholder: 'ContentWizard.Label.ContentDescription.Placeholder',
};

const addPageTypeString = {
  configuration: 'ContentWizard.Label.PageConfiguration',
  addItem: 'ContentWizard.WebPage.Title',
  searchPlaceholder: 'ContentWizard.Label.AddPage.Placeholder',
  itemSize: 'ContentWizard.Label.ContentSize',
  itemDescription: 'ContentWizard.Label.ContentDescription',
  descrPlaceholder: 'ContentWizard.Label.ContentDescription.Placeholder',
};

const addPhotographyTypeStrings = {
  configuration: 'ContentWizard.Label.PhotographyConfiguration',
  addItem: 'ContentWizard.Label.AddPhotography',
  searchPlaceholder: 'ContentWizard.Label.AddPhotography.Placeholder',
  itemSize: 'ContentWizard.Label.PhotographyItemSize',
  itemDescription: 'ContentWizard.Label.PhotographyDescr',
  descrPlaceholder: 'ContentWizard.Label.PhotographyDescr.Placeholder',
};


export const getStringsObjectByType = (type) => {
  switch (type) {
    case ContentItemTypes.article:
      return addArticleTypeStrings;
    case ContentItemTypes.collection:
      return addCollectionTypeString;
    case ContentItemTypes.pdf:
      return addPdfTypeString;
    case ContentItemTypes.video:
      return addVideoTypeStrings;
    case ContentItemTypes.other:
    case ContentItemTypes.ownVideo:
      return addOtherTypeString;
    case ContentItemTypes.page:
      return addPageTypeString;
    case ContentItemTypes.photography:
      return addPhotographyTypeStrings;  
    default:
      // eslint-disable-next-line no-console
      console.error('Unhandled type');
      break;
  }
};

export const productTypeData = [
  { value: '', label: '' },
  { value: '0', label: 'ProductSearch.Subcategory.CarpetTile' },
  { value: '1', label: 'ProductSearch.Subcategory.Broadloom' },
  { value: '2', label: 'ProductSearch.Subcategory.LVT' },
  { value: '3', label: 'ProductSearch.Subcategory.COREtec' },
  { value: '4', label: 'ProductSearch.Subcategory.Sheet' },
  { value: '5', label: 'ProductSearch.Subcategory.BioBasedResilient' },
  { value: '6', label: 'ProductSearch.Subcategory.HighDensityCore' },
  { value: '7', label: 'ProductSearch.Subcategory.PlyCore' },
  { value: '8', label: 'ProductSearch.Subcategory.Rugs' },
  { value: '9', label: 'ProductSearch.Subcategory.Paint' },
  { value: '10', label: 'ProductSearch.Subcategory.EntrywayCarpetTile' },
  { value: '11', label: 'ProductSearch.Subcategory.EntrywayBroadloom' }
];
