/* eslint-disable no-console */

export function isScreenWidthLessThan(breakpoint) {
  return typeof breakpoint === 'number'
    ? window.innerWidth < breakpoint
    : console.error('breakpoint is not a number');
}

export function isScreenWidthGreaterThan(breakpoint) {
  return typeof breakpoint === 'number'
    ? window.innerWidth > breakpoint
    : console.error('breakpoint is not a number');
}
