export const boardRoles = {
  owner: 3,
  collaborator: 2,
  commenter: 1,
  viewer: 0,
  3: 'Owner',
  2: 'Collaborator',
  1: 'Commenter',
  0: 'Viewer',
};

const { i18n } = ShawContract;

export const rolesDropdown = [
  {
    role: boardRoles.owner,
    label: i18n['SharedBoardModal.Label.Owner'],
    description: i18n['SharedBoardModal.Owner.Description'],
    showAsOption: false,
  },
  {
    role: boardRoles.collaborator,
    label: i18n['ShareBoardModal.Collaborator'],
    description: i18n['ShareBoardModal.Collaborator.Description'],
    showAsOption: true,
  },
  {
    role: boardRoles.commenter,
    label: i18n['ShareBoardModal.Commenter'],
    description: i18n['ShareBoardModal.Commenter.Description'],
    showAsOption: true,
  },
  {
    role: boardRoles.viewer,
    label: i18n['ShareBoardModal.Viewer'],
    description: i18n['ShareBoardModal.Viewer.Description'],
    showAsOption: true,
  },
];

export const showRolesDropDown = (userRole, shareUserRole) => {
  const userIndex = rolesDropdown.findIndex((user) => user.role === userRole);
  const shareUserIndex = rolesDropdown.findIndex((user) => user.role === shareUserRole);
  return shareUserIndex <= userIndex;
};

export const getUserRoleLabel = (userRole) => rolesDropdown.find((user) => user.role === userRole)?.label || '';
