import { createElement } from './createElement';

let wasPreventScrollStopped = false;


function removeChildren(parent) {
  while (parent.firstChild) {
    parent.removeChild(parent.firstChild);
  }
}

function showElement(elem) {
  elem.classList.add('shaw-show');
  elem.classList.remove('shaw-hide');
  setTimeout(() => {
    elem.classList.add('animate');
  }, 0);
}

function hideElement(elem) {
  elem.classList.remove('animate');
  elem.classList.remove('shaw-show');
  elem.classList.add('shaw-hide');
}

function debounce(fn, ms) {
  let time = null;
  return function () {
    const a = arguments;
    const t = this;
    clearTimeout(time);
    time = setTimeout(() => {
      fn.apply(t, a);
    }, ms);
  };
}

function copyToClipboard(text, node = document.body) {
  if (window.clipboardData && window.clipboardData.setData) {
    // Internet Explorer-specific code path to prevent
    // textarea being shown while dialog is visible.
    return window.clipboardData.setData('Text', text);
  }
  if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
    const textarea = document.createElement('textarea');
    textarea.textContent = text;
    textarea.style.position = 'fixed'; // Prevent scrolling to bottom of page in Microsoft Edge.
    node.appendChild(textarea);
    textarea.select();
    try {
      return document.execCommand('copy'); // Security exception may be thrown by some browsers.
    } catch (ex) {
      // eslint-disable-next-line no-console
      console.warn('Copy to clipboard failed.', ex);
      return false;
    } finally {
      node.removeChild(textarea);
    }
  }

  return undefined;
}

function getPageCulture() {
  const url = location.pathname;
  const currentCulture = url.split('/')[1];

  return currentCulture;
}

/**
 * #### setLink(`productboards/editboard`);
 * ` -> domain.com/en-us/productsboards/editboard `
 * #### setLink(`'productboards/editboard', { userId: 12, projId: 'asdfa' }`);
 * ` -> domain.com/en-us/productsboards/editboard?userId=12&projId=asdfa& `
 * @param {String} link
 * @param {{}} searchParams
 */
function setLink(link = '', searchParams = {}) {
  const params = Object.keys(searchParams)
    .reduce((acc, key) => `${acc}${key}=${searchParams[key]}&`, '');

  return `/${getPageCulture()}/${link}${params && `?${params}`}`;
}

function validateEmails(string) {
  const regex = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
  const result = string.replace(/\s/g, '').split(/,|;/);
  return result.every((str) => regex.test(str));
}

export function throttle(func, delay) {
  let timeout = null;

  return function () {
    const context = this;
    // eslint-disable-next-line prefer-rest-params
    const args = arguments;

    if (!timeout) {
      func.apply(context, args);
      timeout = true;
      setTimeout(() => {
        timeout = false;
      }, delay);
    }
  };
}

function enteredSpecialCharacter(character) {
  const regex = /[!@#$%^&*()_+=]/;
  return regex.test(character);
}

function validatePhoneField(character) {
  const regex = /[0-9 +-]/;
  return regex.test(character);
}

function isCountryWithStates(countryCode) {
  const countriesWithStates = ['CA', 'US'];
  return countriesWithStates.includes(countryCode);
}


function stopPreventingBodyScroll(bodyClasses) {
  if (wasPreventScrollStopped) {
    return;
  }
  wasPreventScrollStopped = true;

  const overlay = document.getElementById('custom-background-overlay');
  const { body } = document;
  bodyClasses = bodyClasses ? `${bodyClasses} prevent-scroll` : 'prevent-scroll';

  if (overlay) {
    $(overlay).remove();
  }

  $(body).removeClass(bodyClasses);

  const scrollY = body.style.top;
  body.style.position = '';
  body.style.top = '';
  body.style.overflowY = '';
  window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
}

function createOverlayElement(classes, bodyClasses) {
  const overlay = createElement({
    classes,
  });
  overlay.id = 'custom-background-overlay';
  document.body.appendChild(overlay);

  $(overlay).on('click', () => {
    stopPreventingBodyScroll(bodyClasses);
  });

  return overlay;
}

function preventBodyScroll(options) {
  wasPreventScrollStopped = false;
  let overlay = document.getElementById('custom-background-overlay');
  const { body } = document;
  const isAlreadyPreventingScrolling = $(body).hasClass('prevent-scroll');
  let bgClasses = 'custom-background-overlay';
  let bodyClasses = 'prevent-scroll';
  let zIndex = 1031;

  if (options) {
    bgClasses += options.bgClasses ? ` ${options.bgClasses}` : '';
    bodyClasses += options.bodyClasses ? ` ${options.bodyClasses}` : '';
    zIndex = options.zIndex || zIndex;
  }

  $(body).addClass(bodyClasses);

  if (!isAlreadyPreventingScrolling) {
    body.style.top = `-${window.scrollY}px`;
    body.style.position = 'fixed';
    body.style.overflowY = 'scroll';
  }

  if (!overlay) {
    overlay = createOverlayElement(bgClasses, bodyClasses);
  }
  overlay.style.zIndex = zIndex;

  if (options && options.onClose) {
    $(overlay).on('click', options.onClose);
  }
}

function filtersCollapse() {
  if (document.getElementById('custom-background-overlay')) {
    stopPreventingBodyScroll('show-filters');
    return;
  }

  document.getElementsByClassName('filters-container')[0].classList.toggle('collapsed');
  document.querySelector('.filters-container .filter-heading i').classList.toggle('fa-chevron-down');
  document.querySelector('.filters-container .filter-heading i').classList.toggle('fa-chevron-up');
}

export const utility = {
  removeChildren,
  showElement,
  hideElement,
  debounce,
  preventBodyScroll,
  stopPreventingBodyScroll,
  copyToClipboard,
  getPageCulture,
  setLink,
  validateEmails,
  enteredSpecialCharacter,
  validatePhoneField,
  isCountryWithStates,
  filtersCollapse,
};
