const ShawContract = window.ShawContract || {};

function capitalizeEveryWord(string) {
  return string
    .split(' ')
    .map((s) => s.charAt(0).toUpperCase() + s.slice(1).toLowerCase())
    .join(' ');
}

function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

function textNodeDOMTraversal(rootElement, callback) {
  if (!rootElement || rootElement.nodeType !== Node.ELEMENT_NODE) {
    return;
  }

  const treeWalker = document.createTreeWalker(rootElement, NodeFilter.SHOW_TEXT, null, false);

  while (treeWalker.nextNode()) {
    const { currentNode } = treeWalker;
    currentNode.textContent = callback(currentNode.textContent);
  }
}

function formatDOMChildren(string) {
  let method = null;

  switch (string) {
    case 'multi-capitalize':
      method = capitalizeEveryWord;
      break;
    case 'capitalize-start':
      method = capitalize;
      break;
    default:
      break;
  }

  if (method) {
    const elements = document.getElementsByClassName(string);

    for (const el of elements) {
      textNodeDOMTraversal(el, method);
    }
  }
}


ShawContract.FormatText = {
  capitalize,
  capitalizeEveryWord,
  textNodeDOMTraversal,
  formatDOMChildren,
};
window.ShawContract = ShawContract;

formatDOMChildren('multi-capitalize');
