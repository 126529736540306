import { utility } from '../modules/utility';
import { createElement } from '../modules/createElement';
import { productClick } from '../modules/GoogleTagManager/productClicks';
import { productImpression } from '../modules/GoogleTagManager/productImpressions';
import { siteSearch as GTMsiteSearch } from '../modules/GoogleTagManager/search';

(function siteSearchInit() {
  let siteSearch = document.getElementById('searchDropDown');
  if (!siteSearch) {
    return;
  }
  siteSearch = siteSearch.parentElement;

  // LOCALIZATION
  const searchTranslations = JSON.parse($('#siteSearchTranslations').val());
  const sectionLabel = searchTranslations.SectionLabel;
  const typeLabel = searchTranslations.TypeLabel;
  const collectionLabel = searchTranslations.CollectionLabel;
  const tagsLabel = searchTranslations.TagsLabel;
  const legalDisclaimerLabel = searchTranslations.LegalDisclaimerLabel;
  // const styleNumberLabel = searchTranslations.StyleNumberLabel;
  const contentTypeLabel = searchTranslations.ContentTypeLabel;
  const colorLabel = searchTranslations.ColorLabel;
  const nlpGiveFeedbackBtnLabel = searchTranslations.GiveFeedbackBtnLabel;
  const nlpHideFeedbackBtnLabel = searchTranslations.HideFeedbackBtnLabel;

  // HARDCODED DATA
  const ALL_TAB = 'all';
  const PAGES_TAB = 'pages';
  const PRODUCTS_TAB = 'products';
  const RESOURCES_TAB = 'resources';
  const TABLET_SIZE = 992;
  const MAX_TAGS_COUNT = 3;

  // ELEMENTS
  const searchInput = document.getElementById('search-input');
  const resultsWrapper = document.getElementById('results-wrapper');
  const noResultsMessage = document.getElementById('no-results');
  const loadingSpinner = document.getElementById('loadingspinner');
  const searchByColorLabel = document.getElementById('search-type-by-color-label');
  const nlpText = document.getElementById('nlptext');
  const nlpLegalDisclaimer = document.getElementById('nlpLegalDisclaimer');
  const siteSearchContactUsWrapper = document.getElementById('siteSearchContactUsWrapper');
  const siteSearchContactUsLink = document.getElementById('siteSearchContactUsLink');
  const useNlpSwitch = document.getElementById('useNlpSwitch');
  const btnGiveFeedback = document.getElementById('giveFeedback');
  const feedbackWrapper = document.getElementById('feedbackWrapper');
  const submitFeedbackBtn = document.getElementById('submitFeedback');
  const feedbackText = document.getElementById('feedbackText');
  const recievedWrapper = document.getElementById('recievedWrapper');
  const feedbackGroup = document.getElementById('nlpFeedbackGroupWrapper');
  const feedbackFailedMsg = document.getElementById('feedbackFailedMsg');
  const giveFeedbackBtnWrapper = document.getElementById('giveFeedbackBtnWrapper');
  const feedbackspinner = document.getElementById('feedbackspinner');
  // Tabs
  const allTab = document.getElementById('all-search-tab');
  const pagesTab = document.getElementById('pages-search-tab');
  const procutsTab = document.getElementById('products-search-tab');
  const resourcesTab = document.getElementById('resources-search-tab');
  // Buttons
  const viewMoreBtn = document.getElementById('view-more');
  const searchInputBtn = document.getElementById('search-input-btn');

  const resultCountHolder = document.getElementById('result-count-holder');
  const searchedWordHolder = document.getElementById('searched-word-holder');
  const searchResultCounterElement = document.querySelector('.search-result-counter');

  // STATE
  let page = 1;
  let searchTerm = '';
  let oldSearchTerm = '';
  let siteSearchData = [];
  let pagesArr = [];
  let productsArr = [];
  let resourcesArr = [];
  let currentTab = ALL_TAB;
  let previousTab = allTab;
  let totalResults = 0;
  let totalPages = 0;
  let totalProducts = 0;
  let totalResources = 0;
  let isColorsSearchMode = false;
  let isReset = true;
  let useNlpChecked = false;
  let nlpResults = '';
  let isEnabledAutoSearch = true;
  let time = null;
  let nlpRequestId = null;

  // DETECT WHERE TO SCROLL
  function scrollToNewElements(allData, newData) {
    const elToScroll = allData.length - newData.length;
    if (newData.length > 0) {
      const elementToFocus = resultsWrapper.getElementsByTagName('li')[elToScroll];
      elementToFocus.scrollIntoView({ behavior: 'smooth' });
      elementToFocus.querySelector('a').focus();
    }
  }

  function hideViewMoreBtn() {
    viewMoreBtn.classList.add('d-none');
  }

  function setSearchCounterString(amount) {
    resultCountHolder.textContent = amount;
    searchedWordHolder.textContent = searchTerm;
  }

  function resetResults() {
    if (isReset === false) {
      $('.nlpSearch').hide();
      $(resultsWrapper).empty();
      hideViewMoreBtn();
      setSearchCounterString('');
      searchResultCounterElement.classList.add('d-none');
      isReset = true;
      nlpResults = '';
    }
  }

  function resetState() {
    page = 1;
    siteSearchData = [];
    pagesArr = [];
    productsArr = [];
    resourcesArr = [];
    resetResults();
  }

  function mapResponseToState(response) {
    pagesArr = [...pagesArr, ...response.Pages];
    productsArr = [...productsArr, ...response.Products];
    resourcesArr = [...resourcesArr, ...response.Resources];
    siteSearchData = [...siteSearchData, ...response.All];
    isColorsSearchMode = response.IsColorsSearchMode;
  }

  function scrollToTopOfResults() {
    resultsWrapper.firstElementChild.scrollIntoView({ behavior: 'smooth' });
  }

  function showViewMoreBtn() {
    viewMoreBtn.classList.remove('d-none');
  }

  function createPageElement(item) {
    const {
      Title, Url, Section,
    } = item;
    const props = [
    ];

    if (Section.Name !== '' && Title.toLowerCase() !== 'home') {
      props.push({ label: sectionLabel, value: Section.Name });
    }

    const el = createElement({
      element: 'li',
      classes: 'item page',
      children: [
        createElement({
          element: 'a',
          classes: 'text-decoration-none stretched-link',
          attributes: { href: Url },
          children: [
            createElement({
              element: 'span',
              classes: 'title',
              text: Title,
            }),
          ],
        }),
        ...props.map(({ label, value }) => createElement({
          element: 'span',
          classes: 'update pr-md-5',
          text: `${label}: `,
          children: [
            createElement({
              element: 'span',
              classes: 'value',
              text: value,
            }),
          ],
        })),
      ],
    });

    resultsWrapper.appendChild(el);
  }

  function createProductElement(item) {
    const {
      Url, Title, StyleNumber, ProductType, Collection, ColorName, ColorNumber,
    } = item;
    const props = [
      { label: typeLabel, value: ProductType },
    ];

    if (Collection && Collection.toLowerCase() !== 'no collection') {
      props.push({ label: collectionLabel, value: Collection });
    }

    if (isColorsSearchMode && ColorName) {
      props.unshift({ label: colorLabel, value: `${ColorName} ${ColorNumber}` });
    }

    const gtmProductData = {
      id: StyleNumber,
      name: Title,
      category: ProductType,
      collectionName: Collection,
      list: 'Navigation Menu Search',
    };

    productImpression(gtmProductData);

    const el = createElement({
      element: 'li',
      classes: 'item product',
      children: [
        createElement({
          element: 'a',
          classes: 'text-decoration-none stretched-link',
          attributes: { href: Url },
          eventListeners: { click: () => productClick(gtmProductData) },
          children: [
            createElement({
              element: 'span',
              classes: 'title',
              text: Title,
              children: [
                createElement({
                  element: 'span',
                  classes: 'text-uppercase',
                  text: ` - ${StyleNumber}`,
                }),
              ],
            }),
          ],
        }),
        ...props.map(({ label, value }) => createElement({
          element: 'span',
          classes: 'update pr-4',
          text: `${label}: `,
          children: [
            createElement({
              element: 'span',
              classes: 'value',
              text: value,
            }),
          ],
        })),
      ],
    });

    resultsWrapper.appendChild(el);
  }

  function createResourceElement(item) {
    const {
      Title, Url, ContentType, Tags, IsPublic,
    } = item;
    const props = [
      { label: contentTypeLabel, value: ContentType },
    ];

    const el = createElement({
      element: 'li',
      classes: 'item resource',
      children: [
        createElement({
          element: 'a',
          classes: 'text-decoration-none stretched-link',
          attributes: { href: Url },
          children: [
            createElement({
              element: 'span',
              classes: 'title',
              text: Title,
              children: [
                createElement({
                  element: 'i',
                  classes: 'far fa-lock-alt',
                  shouldRender: IsPublic !== true,
                }),
              ],
            }),
          ],
        }),
        ...props.map(({ label, value }) => createElement({
          element: 'span',
          classes: 'update pr-md-5',
          text: `${label}: `,
          children: [
            createElement({
              element: 'span',
              classes: 'value',
              text: value,
            }),
          ],
        })),
        createElement({
          element: 'span',
          classes: 'type pr-lg-5 pr-md-5',
          text: `${tagsLabel}: `,
          shouldRender: Tags.length > 0,
          children: [
            ...Tags.slice(0, MAX_TAGS_COUNT).map(({ Name }) => createElement({
              element: 'span',
              classes: 'tag d-inline-block font-weight-normal',
              text: Name,
            })),
            createElement({
              element: 'span',
              classes: 'tag d-inline-block font-weight-normal',
              shouldRender: Tags.length > MAX_TAGS_COUNT,
              children: [
                createElement({
                  element: 'i',
                  classes: 'fal fa-ellipsis-h',
                }),
              ],
            }),
          ],
        }),
      ],
    });

    resultsWrapper.appendChild(el);
  }

  function createSearchElement(item) {
    switch (item.Type) {
      case 'Page':
        createPageElement(item);
        break;
      case 'Product':
        createProductElement(item);
        break;
      case 'Resource':
        createResourceElement(item);
        break;
      default:
        break;
    }
  }

  function generateTemplate(arr, totalItems) {
    $('.standardSearch').show();

    arr.forEach((item) => {
      createSearchElement(item);
    });
    const isResultsDisplayed = resultsWrapper.children.length > 0;

    if (typeof totalItems !== 'object') {
      setSearchCounterString(totalItems);
    }

    if (arr.length > 0) {
      $(noResultsMessage).hide();
      scrollToTopOfResults();
    } else if (!isResultsDisplayed) {
      $(noResultsMessage).show();
    } else {
      resultsWrapper.lastElementChild.scrollIntoView({ behavior: 'smooth' });
    }

    if (arr.length < 6 || totalResults - arr.length <= 0 || (productsArr && (productsArr.length === totalItems.totalPRODUCTS || productsArr.length === totalItems))) {
      hideViewMoreBtn();
    } else {
      showViewMoreBtn();
    }
  }

  // SEARCH DATA
  function newSearchRequest() {
    utility.removeChildren(resultsWrapper);
    resetState();
    $(noResultsMessage).hide();
    $(loadingSpinner).show();
    const leftPartUrl = document.getElementById('searchLink').value;
    const urlParams = `/${searchTerm}/${page}/${useNlpChecked}`;
    const url = leftPartUrl + urlParams;

    $.ajax({
      url,
      type: 'GET',
      data: 'JSON',
      async: true,
      success(response) {
        totalResults = response.TotalItems;

        mapResponseToState(response);
        totalPages = response.totalPAGES;
        totalProducts = response.totalPRODUCTS;
        totalResources = response.totalRESOURCES;

        if (!useNlpChecked) {
          generateTemplate(siteSearchData, response);
        } else {
          $('.standardSearch').hide();
        }

        $(loadingSpinner).hide();

        if (siteSearchData.length > 0) {
          $(noResultsMessage).hide();
          scrollToTopOfResults();
        } else if (!useNlpChecked) {
          $(noResultsMessage).show();
        }

        if (!isColorsSearchMode) {
          $(searchByColorLabel).hide();
        } else {
          $(searchByColorLabel).show();
        }

        if (useNlpChecked && response.NLPResult !== undefined && response.NLPResult !== null && response.NLPResult.trim() !== '') {
          if (response.NLPIsFeedbackEnabled === true) {
            $(feedbackGroup).removeClass('d-none');
            $(btnGiveFeedback).removeClass('d-none');
            $(feedbackText).attr('disabled', false);
            $(submitFeedbackBtn).attr('disabled', false);
          }
          nlpResults = response.NLPResult;
          nlpRequestId = response.NLPRequestId;
          $(searchResultCounterElement).hide();
          $('.nlpSearch').show();
          $(nlpText).html(`${response.NLPResult}`);
          $(nlpLegalDisclaimer).html(`<a href="#" data-toggle="popover" data-trigger="focus" title="${legalDisclaimerLabel}" data-placement="bottom" data-content="${response.NLPLegalDisclaimer}">${legalDisclaimerLabel}</a>`);
          $('[data-toggle="popover"]').popover();
        } else if (useNlpChecked && (response.NLPResult === undefined || response.NLPResult === null || response.NLPResult.trim() === '')) {
          $(noResultsMessage).show();
        } else {
          $('.nlpSearch').hide();
          $(nlpText).html('');
        }

        setSearchCounterString(totalResults);
        searchResultCounterElement.classList.remove('d-none');
        GTMsiteSearch(currentTab, searchTerm, totalResults);
        isReset = false;
      },
    });
  }

  function requestMoreData(tab) {
    if (resultsWrapper) {
      $(loadingSpinner).show();
      hideViewMoreBtn();
      page++;
      /**
                PARAMS FOR THE REQUEST:
                - page
                - searchTerm
              * */
      const leftPartUrl = document.getElementById('searchLink').value;
      const urlParams = `/${searchTerm}/${page}`;
      const url = leftPartUrl + urlParams;

      $.ajax({
        url,
        type: 'GET',
        data: 'JSON',
        async: true,
        success(response) {
          mapResponseToState(response);

          switch (tab) {
            case ALL_TAB:
              generateTemplate(response.All, response);
              scrollToNewElements(siteSearchData, response.All);
              break;

            case PAGES_TAB:
              generateTemplate(response.Pages, totalPages, 'PAGES_TAB');
              scrollToNewElements(pagesArr, response.Pages);
              break;

            case PRODUCTS_TAB:
              generateTemplate(response.Products, totalProducts, 'PRODUCTS_TAB');
              scrollToNewElements(productsArr, response.Products);
              break;

            case RESOURCES_TAB:
              generateTemplate(response.Resources, totalResources, 'RESOURCES_TAB');
              scrollToNewElements(resourcesArr, response.Resources);
              break;
            default:
              break;
          }
        },
      });
    }
  }

  function changeTab(arr, newTab, totalItems = false) {
    if (!Array.isArray(arr)) {
      return;
    }

    currentTab = newTab;
    utility.removeChildren(resultsWrapper);
    generateTemplate(arr, totalItems, newTab);

    if (siteSearchData.length === 0) {
      $(noResultsMessage).show();
    }
  }

  function onClose() {
    const elementsToShow = document.querySelectorAll('.hidden-menu-item');
    if (elementsToShow) {
      elementsToShow.forEach((element) => {
        $(element).show().removeClass('hidden-menu-item');
      });
    }

    if (window.innerWidth >= TABLET_SIZE) {
      utility.stopPreventingBodyScroll();
    }
  }

  function onOpen() {
    utility.preventBodyScroll({ zIndex: 1029, onClose });

    if (window.innerWidth <= TABLET_SIZE) {
      const elementsToHide = document.querySelectorAll('#collapsingPrimaryNavbar .nav-item');
      elementsToHide.forEach((element) => {
        if (!element.classList.contains('search-nav')) {
          $(element).hide().addClass('hidden-menu-item');
        }
      });
    }
  }

  function changeTabStyle(newTab) {
    previousTab.classList.remove('active');
    newTab.classList.add('active', 'animate');
    previousTab = newTab;
  }

  function makeSearchRequest() {
    searchTerm = searchInput.value.toLowerCase().trim()
      .replace(/[[\]@+<>|,/#!$%^&?*;:{}=\\`()]/g, '')
      .replace(/\s{2,}/g, ' ');

    if (searchTerm !== '' && searchTerm !== oldSearchTerm) {
      currentTab = ALL_TAB;
      newSearchRequest();
      changeTabStyle(allTab);
      oldSearchTerm = searchTerm;
    }

    if (!searchTerm && isColorsSearchMode) {
      $(searchByColorLabel).hide();
    }
  }

  function toggleTruncate() {
    if ($(nlpText).hasClass('truncate')) {
      $(nlpText).removeClass('truncate');
    } else {
      $(nlpText).addClass('truncate');
    }
  }

  function searchKeyupHandler(e) {
    if (e.key === 'Enter') {
      makeSearchRequest();
    } else if (isEnabledAutoSearch) {
      clearTimeout(time);
      time = setTimeout(() => {
        makeSearchRequest();
      }, 1000);
    }
  }

  function showHideResultsElements() {
    isEnabledAutoSearch = !useNlpChecked;
    oldSearchTerm = '';
    $(searchInput).focus();

    if (useNlpChecked) {
      $('.standardSearch').hide();

      if (nlpResults.length > 0) {
        $('.nlpSearch').show();
      }

      $(siteSearchContactUsWrapper).addClass('float-right');
      $(siteSearchContactUsWrapper).addClass('nlp-enabled');
      $(siteSearchContactUsLink).addClass('nlp-enabled');
    } else {
      $('.standardSearch').show();
      $('.nlpSearch').hide();
      $(siteSearchContactUsWrapper).removeClass('float-right');
      $(siteSearchContactUsWrapper).removeClass('nlp-enabled');
      $(siteSearchContactUsLink).removeClass('nlp-enabled');
    }
  }

  function submitFeedback() {
    const feedbacktxt = $(feedbackText).val();
    $(feedbackspinner).removeClass('d-none');
    $(feedbackText).attr('disabled', true);
    $(submitFeedbackBtn).attr('disabled', true);
    if (!$(feedbackFailedMsg).hasClass('d-none')) {
      $(feedbackFailedMsg).addClass('d-none');
    }
    const _data = {
      requestId: nlpRequestId,
      feedback: feedbacktxt,
    };

    const url = document.getElementById('feedbackLink').value;
    $.ajax({
      url,
      type: 'POST',
      data: JSON.stringify(_data),
      contentType: 'json',
      async: true,
      success(response) {
        $(feedbackspinner).addClass('d-none');
        if (response === 'Success') {
          $(recievedWrapper).removeClass('d-none');
          $(feedbackWrapper).addClass('d-none');
          $(giveFeedbackBtnWrapper).addClass('d-none');
        } else {
          $(feedbackText).attr('disabled', false);
          $(submitFeedbackBtn).attr('disabled', false);
          if ($(feedbackFailedMsg).hasClass('d-none')) {
            $(feedbackFailedMsg).removeClass('d-none');
          }
        }
      },
    });
  }

  showHideResultsElements();

  // LISTEN FOR EVENTS
  $(siteSearch).on('show.bs.dropdown', onOpen);
  $(siteSearch).on('hide.bs.dropdown', onClose);
  $(siteSearch).on('shown.bs.dropdown', () => {
    searchInput.focus();
  });

  $(allTab).on('click', () => {
    changeTab(siteSearchData, ALL_TAB, totalResults);
    changeTabStyle(allTab);
  });

  $(pagesTab).on('click', () => {
    changeTab(pagesArr, PAGES_TAB, totalPages);
    changeTabStyle(pagesTab);
  });

  $(procutsTab).on('click', () => {
    changeTab(productsArr, PRODUCTS_TAB, totalProducts);
    changeTabStyle(procutsTab);
  });

  $(resourcesTab).on('click', () => {
    changeTab(resourcesArr, RESOURCES_TAB, totalResources);
    changeTabStyle(resourcesTab);
  });

  $(viewMoreBtn).on('click', () => {
    requestMoreData(currentTab);
  });

  $(nlpText).on('click', toggleTruncate);

  searchInput.addEventListener('keyup', searchKeyupHandler);

  $(searchInput).on('keyup', () => {
    utility.debounce(makeSearchRequest, 1000);
  });

  $(searchInputBtn).on('click', () => {
    makeSearchRequest();
  });

  $(useNlpSwitch).on('change', () => {
    useNlpChecked = $(useNlpSwitch).is(':checked');
    showHideResultsElements();
  });

  $(btnGiveFeedback).on('click', () => {
    if ($(feedbackWrapper).hasClass('d-none')) {
      $(feedbackWrapper).removeClass('d-none');
      $(btnGiveFeedback).text(nlpHideFeedbackBtnLabel);
      $(feedbackText).focus();
    } else {
      $(feedbackWrapper).addClass('d-none');
      $(btnGiveFeedback).text(nlpGiveFeedbackBtnLabel);
      $(searchInput).focus();
    }
  });

  $(submitFeedbackBtn).on('click', () => {
    submitFeedback();
  });
}());
