import { getVariant } from './getVariantFormat';

export function onAddToBoardProduct(data) {
  const { currentTarget } = data;
  let eventData = data;

  if (currentTarget) {
    eventData = $(currentTarget).data('gtm-event');
  }

  if (eventData) {
    dataLayer.push({
      event: 'add-to-board',
      ecommerce: {
        items: eventData,
      },
    });
  }
}

export function formatGoogleTagProductData(data, price) {
  const { productContentItem, contentItem } = data;
  const { productStyleImages } = productContentItem || {};
  const { title } = contentItem;

  if (Array.isArray(productStyleImages) && data) {
    return productStyleImages.map((item, index) => ({
      id: productContentItem?.productId,
      name: title,
      brand: 'shawcontract',
      category: productContentItem?.productInventoryType,
      variant: getVariant(item.colorName, item.colorNumber),
      list: 'unfiltered',
      listId: 1,
      index,
      quantity: 1,
      price,
    }));
  }

  return {};
}

export function formatGoogleTagBMPaintData(data) {
  const { contentItems } = data;

  if (Array.isArray(contentItems)) {
    return contentItems.map((item, index) => ({
      id: '5A291',
      name: item.title,
      brand: 'shawcontract',
      variant: item.paintContentItem.paintColorNumber,
      list: 'unfiltered',
      listId: 1,
      index,
      quantity: 1,
    }));
  }

  return {};
}

export function onAddToBoardContent(data) {
  if (data) {
    dataLayer.push({
      event: 'add_to_board',
      name: data.contentItem.title,
      contentType: data.contentType,
    });
  }
}
