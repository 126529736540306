// Copyright Joyent, Inc. and other Node contributors.
//
// Permission is hereby granted, free of charge, to any person obtaining a
// copy of this software and associated documentation files (the
// "Software"), to deal in the Software without restriction, including
// without limitation the rights to use, copy, modify, merge, publish,
// distribute, sublicense, and/or sell copies of the Software, and to permit
// persons to whom the Software is furnished to do so, subject to the
// following conditions:
//
// The above copyright notice and this permission notice shall be included
// in all copies or substantial portions of the Software.
//
// THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS
// OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF
// MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN
// NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
// DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
// OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
// USE OR OTHER DEALINGS IN THE SOFTWARE.

const query = {};
const arrayValuesSeperator = '__';

const stringifyPrimitive = function (v) {
  switch (typeof v) {
    case 'string':
      return v;

    case 'boolean':
      return v ? 'true' : 'false';

    case 'number':
      return isFinite(v) ? v : '';

    default:
      return '';
  }
};

query.encode = function (obj, sep, eq, name) {
  sep = sep || '&';
  eq = eq || '=';
  if (obj === null) {
    obj = undefined;
  }

  if (typeof obj === 'object') {
    return Object.keys(obj).map((k) => {
      const ks = encodeURIComponent(stringifyPrimitive(k)) + eq;
      if (Array.isArray(obj[k])) {
        return ks + obj[k].map((v) => encodeURIComponent(stringifyPrimitive(v))).join(arrayValuesSeperator);
      }
      return ks + encodeURIComponent(stringifyPrimitive(obj[k]));
    }).join(sep);
  }

  if (!name) return '';
  return encodeURIComponent(stringifyPrimitive(name)) + eq
        + encodeURIComponent(stringifyPrimitive(obj));
};

function hasOwnProperty(obj, prop) {
  return Object.prototype.hasOwnProperty.call(obj, prop);
}

query.decode = function (qs, sep, eq, options) {
  sep = sep || '&';
  eq = eq || '=';
  const obj = {};

  if (typeof qs !== 'string' || qs.length === 0) {
    return obj;
  }

  if (qs[0] === '?') {
    qs = qs.slice(1);
  }

  const regexp = /\+/g;
  qs = qs.split(sep);

  let maxKeys = 1000;
  if (options && typeof options.maxKeys === 'number') {
    maxKeys = options.maxKeys;
  }

  let len = qs.length;
  // maxKeys <= 0 means that we should not limit keys count
  if (maxKeys > 0 && len > maxKeys) {
    len = maxKeys;
  }

  for (let i = 0; i < len; ++i) {
    const x = qs[i].replace(regexp, '%20');
    const idx = x.indexOf(eq);
    let kstr; let vstr; let k;
    let v;

    if (idx >= 0) {
      kstr = x.substr(0, idx);
      vstr = x.substr(idx + 1);
    } else {
      kstr = x;
      vstr = '';
    }

    k = decodeURIComponent(kstr);
    v = decodeURIComponent(vstr);
    v = v.split(arrayValuesSeperator);

    if (!hasOwnProperty(obj, k)) {
      obj[k] = v;
    } else if (Array.isArray(obj[k])) {
      obj[k].push(v);
    } else {
      obj[k] = [obj[k], v];
    }
  }

  return obj;
};

/**
 * @function getQueryStrings
 * @description Get all the query strings
 *
 * @returns {Object} An object containing all the key-value pairs of query strings
 */
query.getQueryStrings = function () {
  return this.decode(location.search);
};

/**
 * @function setQueryString
 * @description Changes the queryStrings without redirecting or reloading the page
 * @param  {Object} obj An object containing key-value pairs of query strings
 * @param  {Object} [data] An optional object to pass data between page history.
 */
query.setQueryStrings = function (obj, data) {
  const url = `${location.protocol}//${location.host}${location.pathname}`;
  history.replaceState(data, document.title, `${url}?${this.encode(obj)}`);
};

query.appendQueryString = function (obj, data) {
  const url = `${location.protocol}//${location.host}${location.pathname}`;
  const newState = this.getQueryStrings();
  Object.keys(obj).forEach((k) => {
    if (newState[k]) {
      if (Array.isArray(obj[k])) {
        newState[k].concat(obj[k]);
      } else {
        newState[k].push(obj[k]);
      }
    } else {
      newState[k] = obj[k];
    }
  });
  history.replaceState(data, document.title, `${url}?${this.encode(newState)}`);
};

query.getValue = function (key) {
  const qs = this.getQueryStrings();
  return qs[key];
};

export default query;
