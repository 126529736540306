import { h } from 'preact';
import { Suspense, lazy } from 'preact/compat';
import { useCallback, useReducer } from 'preact/hooks';
import { orderBy } from 'lodash';
import reducer, { initialState as initialReducerState, resetState } from '@redux/Boards/addContentModal/slice';
import { Modal } from 'components/Modal/Modal.jsx';
import { LoadingSpinner } from 'components/LoadingSpinner/LoadingSpinner.jsx';

const WizardMainBody = lazy(() => import(
  /* webpackChunkName: "AddContentWizard" */
  './WizardMainBody.jsx'
));

export const AddContentWizard = ({
  boardId,
  sections,
  SectionMethods,
  addWizardState,
  show,
  onClose = () => {},
}) => {
  const [onOpenState] = addWizardState;
  const initialState = {
    ...initialReducerState,
    contentItem: {
      ...initialReducerState.contentItem,
      type: onOpenState.contentType || 0,
    },
    ...onOpenState,
    sections: orderBy(sections, 'order'),
    boardId,
  };
  const [creationState, dispatch] = useReducer(reducer, initialState);

  const handleClose = useCallback(() => {
    dispatch(resetState());
    onClose();
  }, [onClose]);

  return (
    <Modal id='AddContentWizard' classes='add-content-wizard' show={show} onClose={handleClose} size='md'>
      <Suspense fallback={<LoadingSpinner />}>
        <WizardMainBody
          boardId={boardId}
          sections={sections}
          SectionMethods={SectionMethods}
          addWizardState={addWizardState}
          creationState={creationState}
          dispatch={dispatch}
        />
      </Suspense>
    </Modal>
  );
};
