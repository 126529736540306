import { throttle } from './utility';

const saveMessage = document.getElementById('save-message')?.value;
const errorMessage = document.getElementById('error-message')?.value;

function notification(msg) {
  window.toastr.success(msg || saveMessage);
}

function errorNotification() {
  window.toastr.error(errorMessage);
}

/**
 * If multiple calls are made in a quick period of time,
 * only one message will appear
 */
export const showSaveNotification = throttle(notification, 3000);
export const showErrorNotification = throttle(errorNotification, 3000);
