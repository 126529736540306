export default {
  Types: {
    FACEBOOK: 'FACEBOOK',
    TWITTER: 'TWITTER',
    PINTEREST: 'PINTEREST',
    REDDIT: 'REDDIT',
    LINKEDIN: 'LINKEDIN',
    MAIL: 'MAIL',
  },
  SharingOptions: {
    FACEBOOK: {
      baseURL: 'https://facebook.com/sharer/sharer.php?u=',
    },
    TWITTER: {
      baseURL: 'https://twitter.com/intent/tweet/?url=',
    },
    PINTEREST: {
      baseURL: 'https://pinterest.com/pin/create/button/?url=',
    },
    REDDIT: {
      baseURL: 'https://reddit.com/submit/?resubmit=true&url=',
    },
    LINKEDIN: {
      baseURL: 'https://www.linkedin.com/sharing/share-offsite/?url=',
    },
    MAIL: {
      baseURL: 'mailto:',
    },
  },
};
