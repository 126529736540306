/**
 * @param {{
 *  error: string,
 *  errorCode: number,
 * }} data
 */
export function error(data) {
    const { error, errorCode } = data;

    dataLayer.push({
        event: 'error',
        error: error,
        errorCode: errorCode,
    });
}