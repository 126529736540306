import { Endpoint } from './EndpointClass';
import { utility } from '../utility';
import { CheckAvailability as availabilityDenormalizer } from './Denormalizers/Cart';

const baseMVCUrl = `/${utility.getPageCulture()}/`;
const { i18n } = ShawContract;
const cartAddMsg = i18n['Product.Label.CartSuccess'];

export const CartAPI = {
  AddProducts: new Endpoint('post', 'product/addproductstocart', baseMVCUrl)
    .json().msg(cartAddMsg).getAjax(),

  checkStock: new Endpoint('post', 'product/SampleAvailabilityCheck', baseMVCUrl)
    .json().denormalize(availabilityDenormalizer).getAjax(),
};
