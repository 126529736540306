/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { requestStatusTypes } from 'modules';
import { ContentItemTypes } from 'modules/Boards/Types';
import { mapToStep1Type } from 'components/Boards/AddContentWizard/customData';
// import { fetchResources, fetchMoreResources } from './thunks';

const initialContentItemState = {
  title: '',
  description: '',
  coverPhoto: '',
  thumbnailUrl: '',
  link: '',
  type: 0,
  size: 0,
  row: 0,
  column: 0,
  sectionId: '',
  isItemSelected: false,
  seoUrl: '',
  collectionCode: '',
};

const initialStep2 = {
  productType: null,
  selectedProduct: null,
  searchTerm: '',
  sectionIndex: 0,
  results: [],
  filteredResults: [],
  productTypes: {},
};

const initialResourceStep = {
  searchTerm: '',
  page: 1,
  results: [],
  resultsCount: null,
  loadingStatus: requestStatusTypes.idle,
  loadingMoreStatus: requestStatusTypes.idle,
  selectedIndex: null,
  selectedTags: [],
  tagsList: [],
};

const initialBoardStep = {
  searchTerm: '',
  page: 1,
  results: [],
  resultsCount: null,
  selectedIndex: null,
  boards:[],
};

export const initialState = {
  step: 1,
  boardId: '',
  boards: [],
  sections: [],
  contentItem: initialContentItemState,
  product: {
    productStyleImages: [],
    savedImages: [],
  },
  productImageData: {
    galleryImages: [],
    colors: [],
    roomScenes: [],
    installationMethods: [],
  },
  step1: {
    selectedType: null,
  },
  step2: initialStep2,
  paint: {
    selectedPaints: [],
  },
  resourceStep: initialResourceStep,
  boardStep: initialBoardStep,
};

const addContentModalSlice = createSlice({
  name: 'addContentModal',
  initialState,
  reducers: {
    setStep(state, { payload }) {
      state.step = payload;
    },
    setContentItem(state, { payload }) {
      state.contentItem = { ...state.contentItem, ...payload };
    },
    setBoard(state, { payload }) {
      const { boardId, sections } = payload;
      state.boardId = boardId || state.boardId;
      state.sections = sections || state.sections;
    },
    setBoards(state, { payload }) {
      state.boards = payload;
    },
    clearContentItem(state) {
      state.contentItem = {
        ...initialContentItemState,
        type: state.contentItem.type,
        size: state.contentItem.size,
        description: state.contentItem.description,
        isItemSelected: false,
      };
    },
    setProduct(state, { payload }) {
      state.product = { ...state.product, ...payload };
    },
    setProductImageData(state, { payload }) {
      state.productImageData = payload;
      state.product = {
        ...state.product,
        productInventoryType: payload.inventoryType?.toUpperCase(),
        productType: payload.productType,
      };
    },
    setStep1(state, { payload }) {
      state.step1 = { ...state.step1, ...payload };
    },
    setStep2(state, { payload }) {
      state.step2 = { ...state.step2, ...payload };
    },
    setProductTypes(state, { payload }) {
      state.step2 = {
        ...state.step2,
        productTypes: payload,
      };
    },
    setResourceStep(state, { payload }) {
      state.resourceStep = {
        ...state.resourceStep,
        ...payload,
      };
    },
    setBoardStep(state, { payload }) {
      state.boardStep = {
        ...state.boardStep,
        ...payload,
      };
    },
    resetStep2(state, { payload }) {
      state.step2 = {
        ...state.step2,
        productType: payload,
        selectedProduct: null,
        results: [],
        filteredResults: [],
        searchTerm: '',
      };
      state.contentItem = { ...state.contentItem, type: ContentItemTypes.product };
    },
    setContentType(state, { payload }) {
      state.contentItem = { ...state.contentItem, type: payload };
      state.step1 = { ...state.step1, selectedType: mapToStep1Type(payload) };
    },
    resetContentType(state) {
      return {
        ...initialState,
        boardId: state.boardId,
        sections: state.sections,
        boards: state.boards,
        step: state.step,
        step2: {
          ...initialStep2,
          sectionIndex: state.step2.sectionIndex,
        },
        contentItem: { ...initialContentItemState, description: state.contentItem.description },
      };
    },
    setSelectedPaints(state, { payload }) {
      state.paint = { ...state.paint, selectedPaints: payload };
    },
    resetState() {
      return { ...initialState };
    },
  },
});

export const {
  clearContentItem,
  resetContentType,
  resetState,
  resetStep2,
  setBoard,
  setBoards,
  setBoardStep,
  setContentItem,
  setContentType,
  setProduct,
  setProductImageData,
  setResourceStep,
  setSelectedPaints,
  setStep,
  setStep1,
  setStep2,
  setProductTypes,
} = addContentModalSlice.actions;

export default addContentModalSlice.reducer;
