// this file is responsible for exporting GTM (Google Tag Managment) tracking functions for (inside the) Board
// shawcontract.com/en-us/productboards/editboard/~board id~

export function boardSectionAction(
  sectionAction,
  boardType,
  boardId,
) {
  window.dataLayer.push({
    event: 'boards-section-action',
    sectionAction,
    boardType,
    boardId,
  });
}

export function boardAddItem(
  stepName,
  stepNumber,
  itemAction = undefined,
  contentType = undefined,
  boardType,
  boardId,
) {
  window.dataLayer.push({
    event: 'board-add-item',
    stepName,
    stepNumber,
    itemAction,
    contentType,
    boardType,
    boardId,
  });
}

export function boardEditItem(
  editActionType,
  itemAction,
  boardType,
  boardId,
  productCollection = undefined,
) {
  window.dataLayer.push({
    event: 'board-edit-item',
    editActionType,
    itemAction,
    productCollection,
    boardType,
    boardId,
  });
}

export function boardShareAttempt(
  boardId = undefined,
) {
  window.dataLayer.push({
    event: 'share-board-attempt',
    boardId,
  });
}

export function boardShareFail(
  boardId = undefined,
  failureReason = undefined,
) {
  window.dataLayer.push({
    event: 'share-board-fail',
    boardId,
    failureReason,
  });
}

export function boardShareSuccess(
  boardId = undefined,
) {
  window.dataLayer.push({
    event: 'share-board-complete',
    boardId,
  });
}

export function boardShareLink(
  boardId = undefined,
) {
  window.dataLayer.push({
    event: 'share-board-copy',
    boardId,
  });
}