export const boardStatuses = {
  active: 0,
  archived: 1,
};

export const brandBoardStatuses = {
  unpublished: 0,
  draft: 1,
  published: 2,
};

export const boardTypes = {
  personal: 0,
  brand: 1,
  activeType: 'Active',
  archivedType: 'Archived',
  brandType: 'Brand',
};
