/**
 * Don't forget to add the Cropper css style to the scss bundle page
 * where you plan to use this module.
 *
 * Also, initialize it after the parent of the image is visible on the page
 * (has height and width)
 */


import noUiSlider from 'nouislider';
import Cropper from 'cropperjs'

const defaultSliderOptions = {
    start: 1.0,
    min: 1.0,
    max: 3.0,
    step: 0.1,
};

const defaultCropperOptions = {
    viewMode: 2,
    dragMode: 'move',
    zoomOnWheel: false,
    autoCrop: false,
    toggleDragModeOnDblclick: false,
    background: false,
    minContainerHeight: 500,
}

export class ImageControls {
    /**
     * @param {HTMLElement} sliderElement
     * @param {HTMLElement} image
     * @param {{
     *  start: number,
     *  step: number,
     *  min: number,
     *  max: number,
     * }=} sliderOptions
     * @param {{}=} cropperOptions
     */
    constructor(sliderElement, image, sliderOptions, cropperOptions) {
        if (!sliderElement) {
            return null;
        }

        this.sliderElement = sliderElement;
        this.image = image;
        this.sliderOptions = { ...defaultSliderOptions, ...sliderOptions };
        this.createSlider();

        this.cropper = new Cropper(image, { ...defaultCropperOptions, ...cropperOptions });

        $(this.image).one('ready', this.afterInit.bind(this));
        return this;
    }

    createSlider() {
        const {
      start, step, min, max,
      } = this.sliderOptions;

        if (this.sliderElement.noUiSlider) {
            this.sliderElement.noUiSlider.destroy();
        }

        noUiSlider.create(this.sliderElement, {
            start,
            step,
            range: {
                min,
                max,
            },
            orientation: 'vertical',
            direction: 'rtl',
            connect: 'lower',
        });

        this.sliderElement.noUiSlider.on('change', this.setZoom.bind(this));
        return this;
    }

    setZoom(number) {
        const sliderValue = number || parseFloat(this.sliderElement.noUiSlider.get());
        const initialScale = this.cropper.initialCanvasData.scale;
        this.cropper.zoomTo(sliderValue * initialScale);
        this.sliderElement.noUiSlider.set(sliderValue);
    }

    afterInit() {
        this.setZoom();
    }
}
