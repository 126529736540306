const defaultValues = {
  id: undefined,
  name: undefined,
  brand: 'shawcontract',
  category: undefined,
  variant: undefined,
  price: undefined,
  position: undefined,
};

/**
 *
 * @param {{
  id: string,
  name: string,
  category: string,
  variant: string,
  price: string,
  list: string,
  position: string,
  } | Event} data
 */
export function productClick(data, currencyCode = 'USD') {
  let eventData = data;
  const { currentTarget } = data;

  if (currentTarget) {
    eventData = $(currentTarget).data('gtm-event');
  }
  const { list = 'unfiltered' } = eventData;
  eventData = { ...eventData, category: eventData.category?.toLowerCase() };

  dataLayer.push({
    event: 'product-click',
    ecommerce: {
      currencyCode,
      click: {
        actionField: {
          list,
        },
        products: [{
          ...defaultValues,
          ...eventData,
          dimension3: undefined,
          dimension4: undefined,
          dimension5: undefined,
          dimension6: undefined,
          metric1: undefined,
          metric4: undefined,
          metric14: undefined,
        }],
      },
    },
  });
}

export function addProductClickEventListeners() {
  const productLink = document.querySelectorAll('.gtm-product-click');

  for (const link of productLink) {
    $(link).on('click', productClick);
  }
}
