/* eslint-disable no-empty */

import { utility } from '../modules/utility';

const mobileMenuCollapse = document.getElementById('collapsingPrimaryNavbar');

// Helper functions
function adjustNavPositionBasedOnScroll() {
  const howFarHasUserScrolled = window.pageYOffset;
  const bodyTop = parseInt(getComputedStyle(document.body).getPropertyValue('top'), 10);

  if (howFarHasUserScrolled !== 0 || !Number.isNaN(bodyTop)) {
    document.body.classList.add('scrolling');
  } else {
    document.body.classList.remove('scrolling');
  }
}
function addClass(el, cls) {
  if (el) {
    el.classList.add(cls);
  }
}

function startAnimation() {
  const windowHeight = 650;
  const dancingSquareWidgets = document.getElementsByClassName(
    'dancing-squares',
  );

  if (dancingSquareWidgets.length > 0) {
    const [firstDancingSquareWidget] = dancingSquareWidgets;

    if (firstDancingSquareWidget.offsetTop <= windowHeight) {
      addClass(firstDancingSquareWidget, 'now-viewable');
    }
  }
}

function captureMenuClick() {
  const siteMenu = document.getElementById('site-menu');
  if (!siteMenu) return;

  const dropdowns = siteMenu.querySelectorAll('.dropdown-menu');

  for (let i = 0; i < dropdowns.length; i++) {
    const dropdown = dropdowns[i];

    dropdown.addEventListener('click', (event) => {
      if (event.target.id !== 'createNewBoardBtn') {
        event.stopPropagation();
      }
    });
  }
}

function addWidgetsListeners() {
  const widgets = document.querySelectorAll('.widget');
  if (!widgets) {
    return;
  }

  function addListener(target) {
    const io = new IntersectionObserver(((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          target.classList.add('now-viewable');
          observer.disconnect();
        }
      });
    }), { threshold: 0.33 });

    io.observe(target);
  }

  widgets.forEach(addListener);
}

function checkSiteMenuOnResize() {
  const mobileMenu = document.getElementById('collapsingPrimaryNavbar');

  if (!mobileMenu) {
    return;
  }

  const isMobileMenuOpen = $(mobileMenu).hasClass('show');

  if (isMobileMenuOpen && window.innerWidth >= 992) {
    $(mobileMenu).collapse('hide');
    utility.stopPreventingBodyScroll();
  }
}

function calcFloatingImageWidgetHeight(w) {
  let totalHeight = 0;
  const padding = 135;
  const title = w.querySelector('.title-section');
  const imgContainer = w.querySelector('.img-box');
  const card = w.querySelector('.card');

  totalHeight += title.offsetHeight;
  totalHeight += padding;

  if (card.offsetHeight > imgContainer.offsetHeight) {
    totalHeight += card.offsetHeight;

    if (getComputedStyle(imgContainer).getPropertyValue('position') === 'relative') {
      totalHeight += imgContainer.offsetHeight;
    }
  } else {
    totalHeight += imgContainer.offsetHeight;

    if (getComputedStyle(imgContainer).getPropertyValue('position') === 'relative') {
      totalHeight += card.offsetHeight;
    }
  }

  w.style.height = `${totalHeight}px`;
}

function dancingSquareWidget() {
  const widgets = document.querySelectorAll('.dancing-squares');

  if (!widgets) {
    return;
  }

  widgets.forEach((w) => {
    const img = w.querySelector('.dance-img');

    img.addEventListener('load', () => {
      calcFloatingImageWidgetHeight(w);
    });
  });
}

const recalcDancingSquareWidgetsHeight = utility.debounce(() => {
  const widgets = document.querySelectorAll('.dancing-squares');

  if (!widgets) {
    return;
  }

  widgets.forEach((w) => {
    calcFloatingImageWidgetHeight(w);
  });
}, 200);

// Aggregate calls on common window actions
function onPageScroll() {
  adjustNavPositionBasedOnScroll();
}

function onPageResize() {
  dancingSquareWidget();
  checkSiteMenuOnResize();
  recalcDancingSquareWidgetsHeight();
}

export function onDocumentLoaded() {
  try {
    dancingSquareWidget();
    addWidgetsListeners();

    // Enable bootstraip tooltip
    $('[data-toggle="tooltip"]').tooltip();

    // Attempt to download file to users computer
    $('.download-this').click(async function (e) {
      e.preventDefault();
      const that = $(this);
      const link = document.createElement('a');
      document.body.appendChild(link);
      // removing '?pixels=XXX' query to be able to download img in best quality
      const originalLink = that.attr('data-download');
      const linkWithRemovedPixels = originalLink.substring(0, originalLink.indexOf('?pixels')) || originalLink.substring(0, originalLink.indexOf('&pixels')) || originalLink;
      link.href = linkWithRemovedPixels.includes('?') ? await toDataURL(`${linkWithRemovedPixels}&pixels=20000&dl=true`) : `${linkWithRemovedPixels}?pixels=20000&dl=true`;
      link.setAttribute('download', '');
      link.click();
      document.body.removeChild(link);
    });
  } catch (error) {}
}

function toDataURL(url) {
  return fetch(url).then((response) => {
    return response.blob();
  }).then((blob) => {
    return URL.createObjectURL(blob);
  });
}
function onMobileMenuClose() {
  $('#collapsingPrimaryNavbar').removeClass('show');
}

$(mobileMenuCollapse).on('show.bs.collapse', () => {
  utility.preventBodyScroll({
    bodyClasses: 'small-screen',
    bgClasses: 'light-dark',
    zIndex: 1029,
    onClose: onMobileMenuClose,
  });
});
$(mobileMenuCollapse).on('hide.bs.collapse', () => {
  utility.stopPreventingBodyScroll('small-screen');
});

// Invoke Immediately
adjustNavPositionBasedOnScroll();
startAnimation();
captureMenuClick();
window.addEventListener('scroll', utility.debounce(onPageScroll, 100));
window.addEventListener('resize', onPageResize);
$(document).ready(onDocumentLoaded);
