/* eslint-disable max-len */
import Swiper, { Navigation, Pagination } from "swiper";
Swiper.use([Navigation, Pagination]);

let scrollOnChange = false;

// Set default Swiper options
const defaultSwiperOptions = {
  direction: "horizontal",
  slidesPerView: 1,
  loop: true,
  centerInsufficientSlides: true,
  watchOverflow: true,
  watchSlidesProgress: true,
  watchSlidesVisibility: true,
  touchEventsTarget: "wrapper",
  slideVisibleClass: "swiper-slide-visible",
  pagination: {
    el: ".swiper-pagination",
    type: "custom",
    clickable: true,
    renderCustom: renderCustomPagination,
  },
  a11y: {
    prevSlideMessage: "Previous slide",
    nextSlideMessage: "Next slide",
    firstSlideMessage: "First slide",
    lastSlideMessage: "Last slide",
  },
  keyboard: {
    enabled: true,
    onlyInViewport: true,
  },
  on: {
    init: function (swiper) {
      swiper.wf = {};
      wfInitializeNavbar(swiper);
      wfUpdateActiveNavbarButton(swiper);
      wfInitializeThumbnails(swiper);
      wfUpdateActiveThumbnail(swiper);
      wfInitializeNavButtons(swiper);
    },
    slideChange: function (swiper) {
      wfUpdateActiveNavbarButton(swiper);
      wfUpdateActiveThumbnail(swiper);
    },
  },
};

// Render custom pagination
function renderCustomPagination(swiper, current, total) {
  let paginationHtml = "";

  paginationHtml += '<div class="swiper-pagination-bullets">';
  for (let i = 1; i <= total; i++) {
    paginationHtml += `<span class="swiper-pagination-bullet${
      i == current ? " swiper-pagination-bullet-active" : ""
    }"></span>`;
  }
  paginationHtml += "</div>";

  paginationHtml += `<div class="swiper-pagination-counter">${current} of ${total}</div>`;

  return paginationHtml;
}

// Register and initialize Wakefly Swiper navbar
function wfInitializeNavbar(swiperInstance) {
  const wfSwiperNavbar = swiperInstance.el.querySelector(".wf-swiper-navbar");
  if (wfSwiperNavbar) {
    swiperInstance.wf.SwiperNavbar = wfSwiperNavbar;

    const allNavbarButtons = swiperInstance.wf.SwiperNavbar.querySelectorAll(
      ".wf-swiper-navbar-button"
    );
    allNavbarButtons.forEach((b, i) => {
      b.addEventListener("click", function () {
        swiperInstance.slideTo(i + 1);
      });
    });
  }
}

// If Wakefly Swiper navbar exists, then mark the current slide's navbar button as active
function wfUpdateActiveNavbarButton(swiperInstance) {
  if (swiperInstance.wf && swiperInstance.wf.SwiperNavbar) {
    const allNavbarButtons = swiperInstance.wf.SwiperNavbar.querySelectorAll(
      ".wf-swiper-navbar-button"
    );
    const currentNavbarButton = allNavbarButtons.item(swiperInstance.realIndex);
    allNavbarButtons.forEach((t) =>
      t.classList.remove("wf-swiper-navbar-button-active")
    );
    if (currentNavbarButton) {
      currentNavbarButton.classList.add("wf-swiper-navbar-button-active");
      let slidePosition =
        (currentNavbarButton.scrollWidth -
          swiperInstance.wf.SwiperNavbar.clientWidth) /
          2 +
        currentNavbarButton.offsetLeft;
      swiperInstance.wf.SwiperNavbar.scrollTo({
        top: 0,
        left: slidePosition,
        behavior: "smooth",
      });
    }
  }
}

// Register and initialize Wakefly Swiper thumbnails
function wfInitializeThumbnails(swiperInstance) {
  const wfSwiperThumbnails = swiperInstance.el.querySelector(
    ".wf-swiper-thumbnails"
  );
  if (wfSwiperThumbnails) {
    swiperInstance.wf.SwiperThumbnails = wfSwiperThumbnails;

    const allThumbnails = swiperInstance.wf.SwiperThumbnails.querySelectorAll(
      ".wf-swiper-thumbnail"
    );
    if (wfSwiperThumbnails.classList.contains("on-hover-thumbnails")) {
      allThumbnails.forEach((t, i) => {
        t.addEventListener("mousemove", function () {
          swiperInstance.slideTo(i + 1);
        });
      });
    } else {
      allThumbnails.forEach((t, i) => {
        t.addEventListener("click", function () {
          swiperInstance.slideTo(i + 1);
        });
      });
    }
  }
}

// If Wakefly Swiper thumbnails exists, then mark the current slide's thumbnail as active
function wfUpdateActiveThumbnail(swiperInstance) {
  if (swiperInstance.wf && swiperInstance.wf.SwiperThumbnails) {
    const allThumbnails = swiperInstance.wf.SwiperThumbnails.querySelectorAll(
      ".wf-swiper-thumbnail"
    );
    const currentThumbnail = allThumbnails.item(swiperInstance.realIndex);
    allThumbnails.forEach((t) =>
      t.classList.remove("wf-swiper-thumbnail-active")
    );
    if (currentThumbnail) {
      currentThumbnail.classList.add("wf-swiper-thumbnail-active");
      if (currentThumbnail.closest(".on-hover-thumbnails") && !scrollOnChange) {
        return;
      }
      let slidePosition =
        (currentThumbnail.scrollWidth -
          swiperInstance.wf.SwiperThumbnails.clientWidth) /
          2 +
        currentThumbnail.offsetLeft;
      swiperInstance.wf.SwiperThumbnails.scrollTo({
        top: 0,
        left: slidePosition,
        behavior: "smooth",
      });
      if (scrollOnChange) {
        scrollOnChange = false;
      }
    }
  }
}

// Register and initialize Wakefly nav buttons
function wfInitializeNavButtons(swiperInstance) {
  const wfSwiperNavPrevButtons = swiperInstance.el.querySelectorAll(
    ".wf-swiper-nav-button-prev"
  );
  const wfSwiperNavNextButtons = swiperInstance.el.querySelectorAll(
    ".wf-swiper-nav-button-next"
  );

  wfSwiperNavPrevButtons.forEach((button) => {
    button.addEventListener("click", function (event) {
      scrollOnChange = true;
      swiperInstance.slidePrev();
    });
  });

  wfSwiperNavNextButtons.forEach((button) => {
    button.addEventListener("click", function (event) {
      scrollOnChange = true;
      swiperInstance.slideNext();
    });
  });
}

// Initialize default Swiper instances
const swiperInspirationItems = initializeSwiper(
  ".swiper-container.swiper-wf-inspiration-items"
);
const swiperInspirationInfo = initializeSwiper(
  ".swiper-container.swiper-wf-inspiration-info"
);
const swiperVisualizationStudio = initializeSwiper(
  ".swiper-container.swiper-wf-visualization-studio"
);
const swiperProductBannerSlider = initializeSwiper(
  ".swiper-container.swiper-wf-product-banner-slider"
);
const swiperSolutionsSelector = initializeSwiper(
  ".swiper-container.swiper-wf-solutions-selector"
);
const swiperImageCarousel = initializeSwiper(
  ".swiper-container.swiper-wf-image-carousel"
);

function initializeSwiper(swiperContainer, additionalOptions) {
  const options = { ...defaultSwiperOptions, ...additionalOptions };
  return new Swiper(swiperContainer, options);
}

function destroySwiper(swiperElement) {
  if (swiperElement.swiper) {
    swiperElement.swiper.destroy(true, true);
  }
}

initializeSwiper();

// Export Swiper functions for general use
export default {
  initializeSwiper,
  destroySwiper,
};
