import { h, Fragment } from 'preact';
import cnames from 'classnames';

export const LoadingSpinner = ({
  center = true, inline = false, classes, ...rest
}) => (
  <div class={cnames(
    'flex-grow-1',
    classes,
    { 'd-flex': !inline },
    { 'd-inline': inline },
    {
      'align-items-center justify-content-center': center,
    },
  )}
  {...rest}
  >
    <div class="spinner-border black" role="status">
      <span class="sr-only">Loading</span>
    </div>
  </div>
);
